<template>
  <div>
    <!--Room teacher -->
    <!-- <room-teacher v-if="teacher" :room-id="teacher.roomId" :center-id="teacher.centerId"
                  :logged-teacher="teacher"></room-teacher> -->

    <!--Room Children-->
    <room-children></room-children>

    <!--Tabs -->
    <div class="mt-5 tab-wrapper">
      <vs-tabs class="activity-tab" v-model="selectedTab">
        <vs-tab label="Plan Activities">
          <div>
            <plan-list
              @selectActivity="selectActivityFromPlan"
              @selectFollowUp="selectActivityFromPlan"
            ></plan-list>
          </div>
        </vs-tab>
        <vs-tab label="Learning Stories">
          <div>
            <learning-story
              :selectedFromPlan="selectedActivity"
            ></learning-story>
          </div>
        </vs-tab>
        <vs-tab label="Favourite Activities">
          <div>
            <favourite-activity
              v-if="teacher"
              :teacher="teacher"
            ></favourite-activity>
          </div>
        </vs-tab>
        <vs-tab label="Fast Checks">
          <div>
            <fast-check v-if="teacher" :teacher="teacher"></fast-check>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
// import RoomTeacher from "../RoomTeacher";
import RoomChildren from "../RoomChildren";
import PlanList from "./PlanList";
import LearningStory from "./LearningStory";
import FavouriteActivity from "./FavouriteActivity";
import FastCheck from "./FastCheck";
import {
  getStream,
  getWaysOfLearning
} from "../../../../http/api/assesmentSubject";
import { mapActions } from "vuex";

export default {
  components: {
    RoomChildren,
    PlanList,
    LearningStory,
    FavouriteActivity,
    FastCheck
  },
  data() {
    return {
      selectedActivity: "",
      selectedTab: 0,
      streamList: "",
      waysOfLearningList: ""
    };
  },
  methods: {
    ...mapActions("activity", ["reSetActivityForParent"]),
    selectActivityFromPlan(activity) {
      let self = this;
      
      setTimeout(function() {
        self.selectedActivity = activity;
        self.selectedTab = 1;
        setTimeout(function() {
          self.selectedActivity = "";
        }, 300);
      }, 300);
    },
    async getStreamList() {
      let self = this;
      await getStream().then(res => {
        self.streamList = res.data.data;
      });
    },
    async getWaysOfLearningList() {
      let self = this;
      await getWaysOfLearning().then(res => {
        self.waysOfLearningList = res.data.data;
      });
    }
  },
  mounted() {
    this.getStreamList();
    this.getWaysOfLearningList();
    if (this.$store.state.activity.activity) {
      this.selectActivityFromPlan(this.$store.state.activity.activity);
    }
  },
   beforeDestroy (){
      this.reSetActivityForParent();
    },
  created() {

  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    }
  }
};
</script>
<style>
.product-img {
  height: 75px;
}

.vx-card {
  border-radius: 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
}

table {
  border-collapse: collapse;
}

table tr td {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
}

table tr td:first-child {
  border-left: none;
}
</style>

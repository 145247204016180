import axios from "../../http/axios/index";

/*Get Core Skill list*/
export const getCoreSkills = () => {
    return axios.get('teacher/core-skill');
};

/*Get  ways of learning list*/
export const getWaysOfLearning = () => {
    return axios.get('teacher/ways-of-learning');
};

/*Get Stream list*/
export const getStream = () => {
    return axios.get('teacher/stream');
};


/*Get Stream list*/
export const getAssessments = () => {
    return axios.get('teacher/assessment/list');
};

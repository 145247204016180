<template>
  <div>
    <vx-card class="mb-base">
      <div class="flex flex-wrap">
        <h2>Children's Activities</h2>
        <div class="matrix-btn-wrap ml-auto mb-8">
          <vs-button
            color="primary"
            type="border"
            class="btn-blue-border"
            @click="childPopup = true"
            >Create Fast Check
          </vs-button>
        </div>
      </div>

      <vs-row class="bg-grid-color mb-4">
        <vs-col
          vs-type="flex"
          vs-lg-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <div class="flex datepicker-wrap">
            <span class="btn btn-outline-primary"  @click="previousWeek">
              <i class="feather icon-chevron-left"></i>
            </span>
            <span class="reportrange-text">
              <date-picker
                v-model="week"
                type="week"
                placeholder="Select week"
                prefix-class="xmx"
                ref="picker"
                @change="pickerChange"
                :lang="lang"
                :clearable="false"
                :show-week-number="false"
              >
                <template v-slot:input="{ emit }">{{ dateRange }}</template>
              </date-picker>
            </span>
            <span class="btn btn-outline-primary" @click="nextWeek">
              <i class="feather icon-chevron-right" ></i>
            </span>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <ul class="ml-auto status-list">
            <li>
              <span class="rounded-full" style="background: #76c68f;"></span>
              <span class="font-regular">In progress</span>
            </li>
            <li>
              <span class="rounded-full" style="background: #d1d1d1;"></span>
              <span class="font-regular">Complete</span>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <div class="overflow-auto">
        <table width="100%" class="table fastcheck-table">
          <thead>
            <tr>
              <!-- <th>Fast Check Comparison</th> -->
              <th>Contributor</th>
              <th>Children</th>
              <th>Status</th>
              <th>Action</th>
              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(fastCheck, key) in childFastCheckList"
              :set="(s = getStatus(fastCheck.child, 'new'))"
              :key="key"
            >
              <!-- <td :set="(c = getStatus(fastCheck.child, 'complete'))">
                {{ fastCheck._id }}
              </td> -->
              <td>
                <div class="flex align-items-center flex-wrap">
                  <vs-avatar
                    v-if="fastCheck.teacher.length"
                    size="40px"
                    :src="fastCheck.teacher[0].profileImage"
                    class="mr-4 m-0"
                  />
                  <span class="fast-check-name">{{
                    fastCheck.teacher.length
                      ? fastCheck.teacher[0].fullName
                      : ""
                  }}</span>
                </div>
              </td>
              <td>
                <div class="flex align-items-center">
                  <ul>
                    <li
                      v-for="(child, key) in fastCheck.child"
                      class="inline-block"
                      :key="key"
                    >
                      <vx-tooltip :text="child.childDetail.fullName">
                        <vs-avatar
                          size="40px"
                          v-if="child.childDetail"
                          :src="child.childDetail.photo"
                          class="mr-4"
                        />
                      </vx-tooltip>
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <ul class="ml-auto status-list">
                  <li v-if="s.length > 0">
                    <span
                      class="rounded-full"
                      style="background: #76c68f;"
                    ></span>
                    <span class="font-regular">In progress</span>
                  </li>
                  <li v-else>
                    <span
                      class="rounded-full"
                      style="background: #d1d1d1;"
                    ></span>
                    <span class="font-regular">Complete</span>
                  </li>
                </ul>
              </td>
              <td>
                <a
                  href="javascript:;"
                  v-if="s.length > 0"
                  @click="completeFastCheck(fastCheck)"
                >
                  Fast Check
                </a>
                <a href="javascript:;" v-else @click="viewFastCheck(fastCheck)"
                  >View</a
                >
              </td>
            </tr>
            <tr v-if="childFastCheckList.length < 1">
              <td colspan="5" class="text-center">No fast check found.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>

    <!--Children Add popup-->
    <vs-popup
      class="holamundo add-children-popup"
      title
      :active.sync="childPopup"
    >
      <h2 class="h1 mb-6">Add Children</h2>
      <form>
        <vx-card class="m-0 shadow-none">
          <vs-row>
            <vs-col vs-w="12">
              <vs-input
                class="mb-base w-full search-input"
                icon-pack="feather"
                icon="icon-search"
                v-model="searchText"
                placeholder="Search..."
              />
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12">
              <ul>
                <li>
                  <div class="round flex items-center">
                    <input
                      :id="'checkbox-a'"
                      type="checkbox"
                      @click="selectAllChildren(toggle)"
                    />
                    <label
                      :for="'checkbox-a'"
                      class="text-center justify-center"
                    >
                      <i class="feather icon-check text-sm static"></i>
                    </label>
                    <span
                      style="margin-left: 23px; font-size: 16px; line-height: 22px;"
                      >Select All</span
                    >
                  </div>
                </li>
                <li v-for="(child, key) in options" :key="key">
                  <div class="round flex items-center">
                    <input
                      :id="'checkbox-' + key"
                      name="selectedChildren"
                      type="checkbox"
                      v-model="selectedChildren"
                      :value="child"
                    />

                    <label
                      :for="'checkbox-' + key"
                      class="text-center justify-center"
                    >
                      <i class="feather icon-check text-sm static"></i>
                    </label>
                    <vs-avatar
                      size="46px"
                      :src="child.photo"
                      style="margin-left: 22px;"
                    />
                    <span
                      style="margin-left: 6px; font-size: 16px; line-height: 22px;"
                      >{{ child.fullName }}</span
                    >
                  </div>
                </li>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('selectedChildren')"
                  >{{ errors.first("selectedChildren") }}</span
                >
              </ul>
            </vs-col>
          </vs-row>

          <span class="text-danger text-sm" v-show="errors.has('children')">{{
            errors.first("children")
          }}</span>
        </vx-card>

        <vs-button
          @click="addChildrenToFastCheck"
          color="primary"
          class="ml-auto flex mt-5"
          type="filled"
          :disabled="addSelectedChildren"
          >Add Children
        </vs-button>
      </form>
    </vs-popup>

    <vs-popup
      fullscreen
      v-if="fastCheckPopUp"
      class="holamundo fast-check-popup fast-check-popup1"
      title
      :active.sync="fastCheckPopUp"
    >
      <span id="fast-scroll-top" ref="fastScrollTop"></span>

      <div class="sticky">
        <div class="flex mt-4">
          <!-- <h2 class="m-0">Fast Check {{ this.childFastCheckId }}</h2> -->
          <h2 class="m-0">Observed Growth through Play</h2>
          <vs-button
            color="primary"
            type="gradient"
            @click="addChildrenToExistingFastCheck"
            class="ml-auto "
          >
            Add Children
          </vs-button>
          <vs-button
            v-if="activeTab === children.length - 1"
            color="primary"
            type="gradient"
            @click="nextPrevious(0)"
            class="ml-4"
            >Save
          </vs-button>
        </div>

        <div class="overflow-auto">
          <ul class="teacher-list fastcheck-list">
            <li
              v-for="(child, key) in children"
              :class="key === activeTab ? 'active' : ''"
              style="vertical-align: top"
              :key="key"
            >
              <feather-icon
                icon="XIcon"
                class="m-0 cursor-pointer"
                @click="removeChild(child.childDetail._id, key)"
              />
              <vs-avatar size="large" :src="child.childDetail.photo" />
              <span>{{ child.childDetail.fullName }}</span>
            </li>
          </ul>
        </div>
      </div>

      <vx-card class="mb-base" v-if="children.length">
        <div
          style="margin-top: 30px; margin-bottom: 80px"
          v-for="(check, fastCheckKey) in selectedChildFastCheck"
          :key="check._id"
        >
          <div class="mb-5">
            <h3 class="h3 font-semibold">
              <!-- {{ fastCheckKey + 1 + "." }} {{ check.name }} -->
              {{ check.name }}
            </h3>
            <p>{{ check.question }}</p>
          </div>
          <div class="range">
            <ul class="range-labels">
              <li>
                <input
                  :id="'one' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="1"
                />
                <label :for="'one' + fastCheckKey">
                  <span></span>
                  1
                </label>
              </li>
              <li>
                <input
                  :id="'two' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="2"
                />
                <label :for="'two' + fastCheckKey">
                  <span></span>
                  2
                </label>
              </li>
              <li>
                <input
                  :id="'three' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="3"
                />
                <label :for="'three' + fastCheckKey">
                  <span></span>
                  3
                </label>
              </li>
              <li>
                <input
                  :id="'four' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="4"
                />
                <label :for="'four' + fastCheckKey">
                  <span></span>
                  4
                </label>
              </li>
              <li>
                <input
                  :id="'five' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="5"
                />
                <label :for="'five' + fastCheckKey">
                  <span></span>
                  5
                </label>
              </li>
            </ul>
          </div>
        </div>
        <vs-row>
          <vs-col vs-type="flex" vs-w="12">
            <vs-button
              v-if="activeTab !== 0"
              color="primary"
              type="gradient"
              @click="nextPrevious(-1)"
              class="mr-auto"
              >Previous
            </vs-button>

            <vs-button
              v-if="activeTab !== children.length - 1"
              color="primary"
              type="gradient"
              @click="nextPrevious(1)"
              class="ml-auto"
              >Next
            </vs-button>
            <vs-button
              v-else-if="activeTab === children.length - 1"
              color="primary"
              type="gradient"
              @click="nextPrevious(0)"
              class="ml-auto"
              >Save
            </vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-popup>
    <!--Fast Check Summary Pop up-->
    <vs-popup
      v-if="childAverageFastCheck.length"
      fullscreen
      class="holamundo fast-check-popup"
      title
      :button-close-hidden="false"
      :active.sync="fastCheckSummaryPopUp"
    >
      <h2 class="h1">
        Observed Growth through Play
        <!-- Fast Checks
        {{
          childAverageFastCheck.length
            ? childAverageFastCheck[0]._id.childFastCheckId
            : ""
        }} -->
      </h2>
      <div class="overflow-auto">
        <ul class="fastcheck-list">
          <li
            v-for="(fastCheck, key) in childAverageFastCheck"
            :class="key === activeTab ? 'active' : ''"
            @click="updateActiveTabKey(key)"
            :key="key + 0.1"
          >
            <vs-avatar size="large" :src="fastCheck.childDetail.photo" />
            <span>{{ fastCheck.childDetail.fullName }}</span>
          </li>
        </ul>
      </div>
      <div class="content">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="start"
            vs-align="start"
            vs-lg="8"
            vs-sm="7"
            vs-xs="12"
          >
            <h3 class="h2">Trend Comparison</h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="start"
            vs-align="start"
            vs-lg="4"
            vs-sm="5"
            vs-xs="12"
          >
            <h3 class="h2">Professional development</h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <ul class="rank-title">
              <li class="flex items-center">
                <span class="tag average-tag"></span
                ><span>Individual Average</span>
              </li>
              <li class="flex items-center">
                <span class="tag trend-tag"></span
                ><span>Room/Centre Trend</span>
              </li>
            </ul>
            <span
              style="width:20px;height:20px;border:2px solid #2c2c2c;border-radius:50%;position:absolute;left:-5px;top:-14px"
            ></span>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <div
            v-for="(preset, key) in childAverageFastCheck[activeTab].fastchecks"
            :key="key + 0.2"
            class="w-full flex items-center"
          >
            <vs-col
              vs-type="flex"
              vs-justify="start"
              vs-align="start"
              vs-lg="8"
              vs-sm="12"
              vs-xs="12"
              class="rank-col"
            >
              <div class="flex w-full justify-between">
                <span class="font-semibold">
                  <!-- {{ key + 1 }}. {{ preset.presetDetail.name }} -->
                  {{ preset.presetDetail.name }}
                </span>
                <!-- <span>
                  Rank:
                  <strong>{{ preset.roomRank + 1 }}</strong>
                </span> -->
                <!--<span>
                  Score:
                  <strong>{{ preset.rating }}</strong>
                </span>-->
              </div>
              <ul class="rank-bar">
                <li>
                  <span style="position:absolute;top:5px;">1</span>
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 1"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 1 && Number(preset.trend.toFixed()) === 1
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <!--<span style="width:20px;height:20px;border:2px solid #2c2c2c;border-radius:50%;position:absolute;left:-5px;top:-14px"></span>-->
                  <span
                    v-if="Number(preset.trend.toFixed()) === 1 && Number(preset.averageValue.toFixed()) !== 1"
                    class="tag trend-tag"
                  ></span>
                </li>
                <li>
                  <span style="position:absolute;top:5px;">2</span>
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 2"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 2 && Number(preset.trend.toFixed()) === 2
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <!--<span style="width:20px;height:20px;border:2px solid #2c2c2c;border-radius:50%;position:absolute;left:-5px;top:-14px"></span>-->
                  <span
                    v-if="Number(preset.trend.toFixed()) === 2 && Number(preset.averageValue.toFixed()) !== 2"
                    class="tag trend-tag"
                  ></span>
                </li>
                <li>
                  <span style="position:absolute;top:5px;">3</span>
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 3"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 3 && Number(preset.trend.toFixed()) === 3
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <!--<span style="width:20px;height:20px;border:2px solid #2c2c2c;border-radius:50%;position:absolute;left:-5px;top:-14px"></span>-->
                  <span
                    v-if="Number(preset.trend.toFixed()) === 3 && Number(preset.averageValue.toFixed()) !== 3"
                    class="tag trend-tag"
                  ></span>
                </li>
                <li>
                  <span style="position:absolute;top:5px;">4</span>
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 4"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 4 && Number(preset.trend.toFixed()) === 4
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <!--<span style="width:20px;height:20px;border:2px solid #2c2c2c;border-radius:50%;position:absolute;left:-5px;top:-14px"></span>-->
                  <span
                    v-if="Number(preset.trend.toFixed()) === 4 && Number(preset.averageValue.toFixed()) !== 4"
                    class="tag trend-tag"
                  ></span>
                </li>
                <li style="width: 2%">
                  <span style="position:absolute;top:5px;">5</span>
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 5"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 5 && Number(preset.trend.toFixed()) === 5
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <!--<span style="width:20px;height:20px;border:2px solid #2c2c2c;border-radius:50%;position:absolute;left:-5px;top:-14px"></span>-->
                  <span
                    v-if="Number(preset.trend.toFixed()) === 5 && Number(preset.averageValue.toFixed()) !== 5"
                    class="tag trend-tag"
                  ></span>
                </li>
              </ul>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="start"
              vs-align="center"
              vs-lg="4"
              vs-sm="12"
              vs-xs="12"
              class="rank-link-col"
            >
              <a
                href="javascript:;"
                class="font-semibold"
                @click="
                  detailPage(preset.presetDetail._id, preset.averageValue)
                "
              >
                Link to
                {{ preset.presetDetail.name }} Resources
              </a>
            </vs-col>
          </div>
        </vs-row>

        <vs-button
          @click="fastCheckSummaryPopUp = false"
          color="primary"
          class="ml-auto flex mt-5"
          type="filled"
          >Done
        </vs-button>
      </div>
    </vs-popup>
    <!--Children Add To already created fastcheck popup-->
    <vs-popup
      class="holamundo add-children-popup"
      title
      :active.sync="childExistsPopup"
    >
      <h2 class="h1 mb-6">Add Children</h2>
      <form>
        <vx-card class="m-0 shadow-none">
          <vs-row>
            <vs-col vs-w="12">
              <vs-input
                class="mb-base w-full search-input"
                icon-pack="feather"
                icon="icon-search"
                v-model="searchText"
                placeholder="Search..."
              />
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12">
              <ul>
                <li>
                  <div class="round flex items-center">
                    <input
                      :id="'checkbox-a'"
                      type="checkbox"
                      @click="selectAllChildren(toggle)"
                    />
                    <label
                      :for="'checkbox-a'"
                      class="text-center justify-center"
                    >
                      <i class="feather icon-check text-sm static"></i>
                    </label>
                    <span
                      style="margin-left: 23px; font-size: 16px; line-height: 22px;"
                      >Select All</span
                    >
                  </div>
                </li>
                <li v-for="(child, key) in notExistsChildren" :key="key">
                  <div class="round flex items-center">
                    <input
                      :id="'checkbox-' + key"
                      name="selectedChildren"
                      type="checkbox"
                      v-model="selectedChildren"
                      :value="child"
                    />

                    <label
                      :for="'checkbox-' + key"
                      class="text-center justify-center"
                    >
                      <i class="feather icon-check text-sm static"></i>
                    </label>
                    <vs-avatar
                      size="46px"
                      :src="child.photo"
                      style="margin-left: 22px;"
                    />
                    <span
                      style="margin-left: 6px; font-size: 16px; line-height: 22px;"
                      >{{ child.fullName }}</span
                    >
                  </div>
                </li>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('selectedChildren')"
                  >{{ errors.first("selectedChildren") }}</span
                >
              </ul>
            </vs-col>
          </vs-row>

          <span class="text-danger text-sm" v-show="errors.has('children')">{{
            errors.first("children")
          }}</span>
        </vx-card>

        <vs-button
          @click="storeChildrenToExistFastCheck"
          color="primary"
          class="ml-auto flex mt-5"
          type="filled"
          >Add Children
        </vs-button>
      </form>
    </vs-popup>
  </div>
</template>
<script>
import Vue from "vue";

import vSelect from "vue-select";
import moment from "moment";
import axios from "../../../../http/axios/index";
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
require("../../../../assets/scss/datepicker.scss");

export default {
  components: { vSelect, DatePicker },
  props: ["teacher"],
  data() {
    return {
      week: new Date(),
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      dateRange: this.startOfWeek() + " - " + this.endOfWeek(),
      pickerDateRange: "",
      startDate: this.startOfWeek(),
      endDate: this.endOfWeek(),
      childPopup: false,
      childExistsPopup: false,
      fastCheckPopUp: false,
      selectedChildren: [],
      childFastCheckList: [],
      childrenList: [],
      searchText: "",
      toggle: true,
      options: [],
      fastCheckList: [],
      selectedChild: null,
      childFastCheck: [],
      childAverageFastCheck: [],
      fastCheckSummaryPopUp: false,
      activeTab: 0,
      selectedChildFastCheck: [],
      children: [],
      notExistsChildren: [],
      childFastCheckId: null
    };
  },
  watch: {
    searchText(res) {
      let self = this;
      self.options = self.childrenList;

      let data = self.childrenList;
      let childData = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].fullName.includes(res)) {
          childData.push({
            childId: data[i]._id,
            fullName: data[i].fullName,
            photo: data[i].photo
          });
        }
      }
      self.options = childData;
    }
  },
  methods: {
    ...mapActions("fastCheck", [
      "fetchFastChecks",
      "fetchRandomFastChecks",
      "removeChildFromChildFastCheck"
    ]),
    pickerChange(value) {
      let sDate = this.startOfWeek(value, true);
      let eDate = this.endOfWeek(value, true);

      this.dateRange = sDate + " - " + eDate;
      this.pickerDateRange = sDate + " - " + eDate;
      this.getChildFastCheck(sDate, eDate);
    },
    async addChildrenToExistingFastCheck() {
      await this.getChildrenList(this.teacher.roomId);

      let children = [...this.options];

      const alreadySelectedChildren = this.children.map(item => {
        return item.childDetail._id;
      });
      this.notExistsChildren = _.remove(children, function(item) {
        return !alreadySelectedChildren.includes(item.childId);
      });
      this.childExistsPopup = true;
    },
    async storeChildrenToExistFastCheck() {
      this.$vs.loading();
      let childFastCheckData = [];
      const fastCheckList = this.fastCheckList;
      for (let i = 0; i < this.selectedChildren.length; i++) {
        for (let j = 0; j < fastCheckList.length; j++) {
          let b = {
            roomId: this.teacher.roomId,
            childId: this.selectedChildren[i].childId,
            teacherId: this.teacher._id,
            rating: null,
            FastCheckPresetId: fastCheckList[j]._id,
            activityId: null,
            question: fastCheckList[j].questions.name,
            fastCheckQuestionId: fastCheckList[j].questions._id,
            childFastCheckId: this.childFastCheckId
          };
          childFastCheckData.push(b);
        }
      }
      await axios
        .post(
          "teacher/documentation/fast-check/add-children",
          childFastCheckData
        )
        .then(response => {
          for (let i = 0; i < this.selectedChildren.length; i++) {
            let obj = {
              childDetail: {
                _id: this.selectedChildren[i].childId,
                photo: this.selectedChildren[i].photo,
                fullName: this.selectedChildren[i].fullName
              },
              status: {
                slug: "new"
              }
            };
            this.children.push(obj);
          }
          this.$vs.loading.close();
          this.childExistsPopup = false;
        });
      this.$vs.loading.close();
    },
    async removeChild(childId, key) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async result => {
        if (result.value) {
          const data = {
            childFastCheckId: this.childFastCheckId,
            childId: childId
          };
          await this.removeChildFromChildFastCheck(data)
            .then(res => {
              this.children.splice(key, 1);
            })
            .catch(err => console.log(err));
        }
      });
    },
    async nextPrevious(value) {
      if (value != "-1") {
        const fastCheckWithNullValue = this.selectedChildFastCheck.filter(
          (item, index, arr) => {
            return !item.rating;
          }
        );

        if (fastCheckWithNullValue.length > 0) {
          this.$vs.notify({
            text: "Please check value for all fast checks.",
            title: "Failed",
            color: "danger"
          });
          return;
        }
      }

      this.$vs.loading();
      let fastCheckData = this.selectedChildFastCheck.map(item => {
        return {
          roomId: this.teacher.roomId,
          childId: item.childId,
          FastCheckPresetId: item.FastCheckPresetId,
          teacherId: this.teacher._id,
          rating: item.rating,
          childFastCheckId: this.childFastCheckId,
          question: item.question,
          fastCheckQuestionId: item.fastCheckQuestionId
        };
      });

      await axios
        .post("teacher/documentation/fast-check/update", fastCheckData)
        .then(async response => {
          if (this.activeTab === this.children.length - 1 && value !== -1) {
            this.childFastCheckId = response.data.data.childFastCheckId;
            await axios
              .get("teacher/child-fast-check/" + this.childFastCheckId)
              .then(result => {
                this.activeTab = 0;

                this.childAverageFastCheck = result.data.data;
                this.$vs.loading.close();
              })
              .catch(err => console.log(err));
            this.$vs.loading.close();
            this.fastCheckSummaryPopUp = true;
            this.fastCheckPopUp = false;
            this.getChildFastCheck();
          } else {
            this.childAverageFastCheck = [];
            this.activeTab = this.activeTab + value;
            await axios
              .get(
                "teacher/child-fast-check/" +
                  this.children[this.activeTab].childDetail._id +
                  "/" +
                  this.childFastCheckId
              )
              .then(response => {
                this.getChildFastCheck();

                let data = response.data.data;
                if (data.length > 0) {
                  this.selectedChildFastCheck = data.map(item => {
                    return {
                      name: item.preset.length > 0 ? item.preset[0].name : "",
                      description:
                        item.preset.length > 0
                          ? item.preset[0].description
                          : "",
                      rating: item.rating,
                      childId: item.childId,
                      FastCheckPresetId: item.FastCheckPresetId,
                      fastCheckQuestionId: item.fastCheckQuestionId,
                      question: item.question.name
                    };
                  });
                } else {
                  this.selectedChildFastCheck = this.selectedChildFastCheck.map(
                    item => {
                      return {
                        name: item.name,
                        description: item.description,
                        rating: null,
                        childId: this.children[this.activeTab].childId,
                        FastCheckPresetId: item.FastCheckPresetId,
                        fastCheckQuestionId: item.fastCheckQuestionId,
                        question: item.question
                      };
                    }
                  );
                }
                this.$vs.loading.close();
                document
                  .getElementById("fast-scroll-top")
                  .scrollIntoView({ block: "start" });
                // this.$scrollTo('#fast-scroll-top');
              });
            this.$vs.loading.close();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateActiveTabKey(val) {
      this.activeTab = val;
    },
    getStatus(children, slug) {
      let a = children.map(item => {
        {
          return { slug: item.status.slug };
        }
      });

      return this.lodash.filter(a, { slug: slug });
    },

    async addChildrenToFastCheck() {
      if (this.selectedChildren.length < 1) {
        this.$vs.notify({
          text: "Please select at least one child",
          title: "Failed",
          color: "danger"
        });
        return;
      }
      this.$vs.loading();
      let childFastCheckData = [];

      for (let i = 0; i < this.selectedChildren.length; i++) {
        for (let j = 0; j < this.fastCheckList.length; j++) {
          let b = {
            roomId: this.teacher.roomId,
            childId: this.selectedChildren[i].childId,
            teacherId: this.teacher._id,
            rating: null,
            FastCheckPresetId: this.fastCheckList[j]._id,
            activityId: null,
            question: this.fastCheckList[j].questions.name,
            fastCheckQuestionId: this.fastCheckList[j].questions._id
          };
          childFastCheckData.push(b);
        }
      }
      await axios
        .post(
          "teacher/documentation/fast-check/add-children",
          childFastCheckData
        )
        .then(response => {
          this.getChildFastCheck();
          this.getFastCheck();
          this.selectedChildren = [];
          this.childAverageFastCheck = [];
          this.$vs.loading.close();
          this.childPopup = false;
        });
      this.$vs.loading.close();
    },
    selectAllChildren(res) {
      this.toggle = !res;
      if (res) {
        this.selectedChildren = this.options;
      } else {
        this.selectedChildren = [];
      }
    },
    previousWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }

      this.getChildFastCheck(this.startDate, this.endDate);
    },
    nextWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }
      this.getChildFastCheck(this.startDate, this.endDate);
    },
    async getChildrenList(roomId) {
      await axios.get("teacher/child/" + roomId).then(async children => {
        this.childrenList = await children.data.data;
        this.options = this.childrenList.map(item => {
          return {
            childId: item._id,
            fullName: item.fullName,
            photo: item.photo
          };
        });
      });
    },
    startOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);
      let diff =
        date.getDate() -
        date.getDay() +
        (date.getDay() === 0 ? -6 : 1) +
        (fromPicker ? 1 : 0);
      this.week = date;
      return this.moment(date.setDate(diff)).format("MMMM DD YYYY");

      //return new Date(date.setDate(diff)).toISOString().slice(0, 10);
    },
    endOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);

      let lastDate =
        date.getDate() - (date.getDay() - 1) + 6 + (fromPicker ? 1 : 0);
      return this.moment(date.setDate(lastDate)).format("MMMM DD YYYY");
      //return new Date(date.setDate(lastDate)).toISOString().slice(0, 10);
    },
    moment(date) {
      return moment(date);
    },
    async getChildFastCheck(
      startDate = this.startOfWeek(),
      endDate = this.endOfWeek()
    ) {
      this.$vs.loading();
      let param =
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&roomId=" +
        this.teacher.roomId;
      return await axios
        .get("teacher/documentation/fast-check" + param)
        .then(async response => {
          this.childFastCheckList = await response.data.data;
          this.$vs.loading.close();
        });
      //this.$vs.loading.close();
    },
    /*Get Fast Check List*/
    async getFastCheck() {
      await this.fetchRandomFastChecks(this.teacher.room.ageGroup).then(
        result => {
          this.fastCheckList = result.data.data;
          console.log(this.fastCheckList, "fast check list");
        }
      );
    },
    async completeFastCheck(data) {
      this.activeTab = 0;
      this.children = data.child;
      this.childFastCheckId = data._id;
      await axios
        .get(
          "teacher/child-fast-check/" +
            this.children[0].childDetail._id +
            "/" +
            this.childFastCheckId
        )
        .then(response => {
          let data = response.data.data;
          this.selectedChildFastCheck = data.map(item => {
            return {
              name: item.preset.length > 0 ? item.preset[0].name : "",
              description:
                item.preset.length > 0 ? item.preset[0].description : "",
              rating: item.rating,
              childId: item.childId,
              FastCheckPresetId: item.FastCheckPresetId,
              question: item.question.name,
              fastCheckQuestionId: item.question._id
            };
          });
        });

      this.fastCheckPopUp = true;
    },
    async viewFastCheck(data) {
      let childFastCheckId = data._id;
      await axios
        .get("teacher/child-fast-check/" + childFastCheckId)
        .then(response => {
          this.childAverageFastCheck = response.data.data;
          this.activeTab = 0;
          this.fastCheckSummaryPopUp = true;
        })
        .catch(err => console.log(err));
    },
    detailPage(fastCheckPresetId, rating) {
      let routeData = this.$router.resolve({
        name: "fast-check-document",
        params: { fastCheckPresetId: fastCheckPresetId, rating: rating }
      });
      window.open(routeData.href, "_blank");
    }
  },
  computed: {
    addSelectedChildren: function() {
      return this.selectedChildren.length > 0 ? false : true;
    }
  },
  async created() {
    await this.getChildFastCheck();
    await this.getChildrenList(this.teacher.roomId);
    await this.getFastCheck();
  }
};
</script>

<style scoped lang="scss">
.new {
  background: #76c68f;
}

.complete {
  background: #d1d1d1;
}

.follow-up {
  background: #f3d16c;
}

.fastcheck-table {
  tr {
    th {
      &:nth-child(1) {
        width: 130px;
        min-width: 130px;
        @media (min-width: 1200px) {
          width: auto;
          min-width: auto;
        }
      }

      &:nth-child(2) {
        width: 220px;
        min-width: 200px;
        @media (min-width: 1200px) {
          width: auto;
          min-width: auto;
        }
      }

      &:nth-child(3) {
        width: 400px;
        min-width: 400px;
        @media (min-width: 1200px) {
          width: auto;
          min-width: auto;
        }
      }

      &:nth-child(4) {
        width: 250px;
        min-width: 250px;
        @media (min-width: 1200px) {
          width: auto;
          min-width: auto;
        }
      }
    }
  }

  .status-list {
    li {
      margin-left: 0;
    }
  }
}

.sticky {
  margin: -30px -30px 0;
  padding: 30px 30px 0;
  top: 0;
  z-index: 10;
  background: #ffffff;
}
</style>

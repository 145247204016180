<template>
  <div>
    <vx-card class="mb-base">
      <div class="overflow-auto">
        <table width="100%" class="table favourite-activity-table">
          <thead>
          <tr>
            <th>Activity</th>
            <th>Description</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="favouriteActivities.length" v-for="activity in favouriteActivities" :key="activity._id">
            <td>{{ activity.name }}</td>
            <td>

              <div class="demo-alignment py-3">
                <div class="mt-0">
                  {{ activity.description }}
                  <div class="fill-row-loading demo-alignment">
                                    <span class="inline-block text-center">
                                        <img v-if="activity.coreSkills" :src="activity.coreSkills.icon"
                                             class="inline-block" width="45"
                                             height="45" style="object-fit: scale-down;"/>
                                        <p>{{ activity.coreSkills ? activity.coreSkills.name : '' }}</p>



                                    </span>
                    <span class="inline-block text-center">
                                        <img v-if="activity.waysOfLearnings" :src="activity.waysOfLearnings.icon"
                                             class="inline-block" width="45"
                                             height="45" style="object-fit: scale-down;"/>
                                        <p>{{ activity.waysOfLearnings ? activity.waysOfLearnings.name : '' }}</p>
                                    </span>
                    <span class="inline-block text-center">
                                        <img v-if="activity.stream" :src="activity.stream.icon" class="inline-block"
                                             width="45" height="45" style="object-fit: scale-down;"/>
                                        <p>{{ activity.stream ? activity.stream.name : '' }}</p>
                                    </span>
                  </div>

                </div>
              </div>


              <!--<vx-card>
                <div class="op-block mt-5">
                  <vs-row>
                    <vs-col vs-justify="center" vs-align="center" vs-w="10">
                      <p class="font-bold text-sm">Core Skill</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="2">
                      <p class="font-bold text-sm">Description</p>
                    </vs-col>
                  </vs-row>
                </div>
              </vx-card>-->
              <!--<img v-if="activity.coreSkills" :src="activity.coreSkills.icon" width="75px" height="75px" alt=""/>
              {{activity.coreSkills  ?activity.coreSkills.name :''  }}

              <img v-if="activity.waysOfLearning" :src="activity.waysOfLearnings.icon" width="75px" height="75px"
                   alt=""/>
              {{activity.waysOfLearning ? activity.waysOfLearning.name :''  }}

              <img v-if="activity.stream" :src="activity.stream.icon" width="75px" height="75px"
                   alt=""/>
              {{activity.stream ? activity.stream.name :''  }}
              {{activity.description}}
              <br/>

              <router-link to="/"> Add to planner</router-link>-->

            </td>
            <td>
              <div class="fill-row-loading demo-alignment mt-2">
                <div class="ml-auto heart-icon w-full text-right">
                  <feather-icon v-if="activity.isFavourite === true" icon="HeartIcon"
                                svgClasses="fill-current text-primary"
                                @click="updateFavouriteStatus(activity._id)"/>
                  <feather-icon v-else icon="HeartIcon" svgClasses=" text-primary"
                                @click="updateFavouriteStatus(activity._id)"/>
                </div>
                <div>
                  <a href="#" @click.stop="addActivityToPlannerLink(activity._id)">Add to planner</a>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="favouriteActivities.length < 1">
            <td colspan="2`">No favourite activity.</td>
          </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
  </div>
</template>
<script>
import axios from '../../../../http/axios/index';
import {mapActions} from "vuex";


export default {
  props: ['teacher'],
  data() {
    return {
      favouriteActivities: []
    }
  },
  methods: {
    ...mapActions("activity", [
      "getFavouriteActivities",
      "addActivityToPlanner",
      "changeFavouriteStatus",
    ]),

    /*Favourite status update*/
    async updateFavouriteStatus(id) {
      this.$vs.loading();
      this.changeFavouriteStatus(id).then(async result => {
        this.getFavouriteActivitiesList();
        this.showMessage("Success", "Activity has been removed from Favorite List.", "success");
        this.$vs.loading.close();

      })
        .catch(err => {
          this.$vs.loading.close();
          this.error(err.message);
        })
    },

    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },

    addActivityToPlannerLink(id) {

      this.$vs.loading()
      this.addActivityToPlanner(id)
        .then((res) => {
          this.$vs.loading.close()
          this.showMessage("Success", "Activity added to planner successfully.", "success");

        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })

    },

    getFavouriteActivitiesList() {
      this.getFavouriteActivities(this.teacher.roomId)
        .then((res) => {
          this.$vs.loading.close()
          this.favouriteActivities = this.$store.state.activity.favouriteActivities;
        })
        .catch(err => {
          this.$vs.loading.close()
        })
    },
  },

  mounted() {
    this.getFavouriteActivitiesList();
  }
}
</script>
<style scoped lang="scss">
.favourite-activity-table {
  th {
    &:nth-child(2) {
      min-width: 600px;
      width: 600px;
    }

    &:nth-child(1),
    &:last-child {
      min-width: 200px;
      width: 200px;
    }
  }

  td {
    .demo-alignment {
      align-items: flex-start;

      .text-center {
        // width: 80px;
        margin-right: 50px;
        vertical-align: top;
      }
    }

    &:last-child {
      vertical-align: bottom;
      position: relative;

      .demo-alignment {
        justify-content: flex-end;
      }

      // .heart-icon {
      //   position: absolute;
      //   top: 20px;
      // }
    }
  }
}
</style>

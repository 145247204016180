<template>
  <div>
    <vx-card class="mb-base">
      <div class="flex flex-wrap">
        <h2>Children's Activities</h2>
          <div class="ml-auto mb-8">
            <span class="toggle-switch">
              <RadioToggleButtons
                v-model='displayView'
                :values='values'
                color='#2c98d5'
                textColor='#000'
                selectedTextColor='#fff'
              />
            </span>

          <span>
            <vs-button
              color="primary"
              type="border"
              class="btn-blue-border"
              @click="activityForm"
              v-scroll-to="
                activityFormDisplay ? { el: '#create-form', offset: -100 } : ''
              "
              >Add activity</vs-button
            >
          </span>
        </div>
      </div>
      <vs-row class="bg-grid-color mb-4">
        <vs-col
          vs-type="flex"
          vs-lg-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <div class="flex datepicker-wrap">
            <span class="btn btn-outline-primary"  @click="previousWeek"
              ><i class="feather icon-chevron-left"></i
            ></span>
            <span class="reportrange-text">
              <date-picker
                v-model="week"
                type="week"
                placeholder="Select week"
                prefix-class="xmx"
                ref="picker"
                @change="pickerChange"
                :lang="lang"
                :clearable="false"
                :show-week-number="false"
                :icon-calender="false"
              >
                <template v-slot:input="{ emit }">{{ dateRange }}</template>
              </date-picker>
            </span>
            <span class="btn btn-outline-primary"  @click="nextWeek"
              ><i class="feather icon-chevron-right"></i
            ></span>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <ul class="status-list grid grid-cols-2 xl:grid-cols-4 w-full">
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #76c68f;"></span>
              <span class="font-regular">New</span>
            </li>
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #28388d;"></span>
              <span class="font-regular">Spontaneous</span>
            </li>
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #d1d1d1;"></span>
              <span class="font-regular">Complete</span>
            </li>
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #F3D16C;"></span>
              <span class="font-regular">Follow up</span>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <div class="overflow-auto">
        <table
          class="table children-activity-table"
          width="100%"
          v-if="displayView === 'Matrix'"
        >
          <template v-for="(stream, key) in streams">
            <tr v-if="key === 0" :key="key">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0 && key === 0" :key="ckey"></td>
                <td
                  v-if="key === 0"
                  class="text-center"
                  style="padding: 20px"
                  :key="ckey + 0.3"
                >
                  <span class="img-block">
                    <img
                      :src="skill.icon ? skill.icon : $defaultImage"
                      alt=""
                      class="product-img text-center"
                    />
                  </span>
                  {{ skill.name }}
                </td>
                <td
                  v-if="ckey > 0 && key > 0"
                  class="text-center"
                  :key="ckey + 0.5"
                ></td>
              </template>
            </tr>
            <tr :key="key + 0.1">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0" class="text-left" :key="ckey">
                  <span class="img-block">
                    <img
                      :src="stream.icon ? stream.icon : $defaultImage"
                      alt=""
                      class="product-img"
                    />
                  </span>
                  {{ stream.name }}
                </td>
                <td class="text-center" :key="ckey + 0.2">
                  <ul class="status-list">
                    <li
                      v-for="(index, aKey) in activityMatrix(
                        skill._id,
                        stream._id
                      )"
                      style="display: inline-block"
                      :key="aKey"
                    >
                      <!-- <span
                        v-if="index.parentActivityId && !index.isSpontaneous"
                        @click="
                          index.isCompleted
                            ? showActivityDetail(index)
                            : selectActivity(index)
                        "
                        :class="
                          index.isCompleted
                            ? 'rounded-full complete'
                            : 'rounded-full follow-up'
                        "
                        v-scroll-to="
                          index.isCompleted
                            ? { el: '#activity-detail', offset: -100 }
                            : ''
                        "
                      ></span> -->
                      <span
                        v-if="index.isSpontaneous"
                        @click="
                          index.isSpontaneous
                            ? goToActivity(index)
                            : selectActivity(index)
                        "
                        :class="
                          index.isSpontaneous
                            ? 'rounded-full spontaneous'
                            : 'rounded-full follow-up'
                        "
                      ></span>
                      <span
                        v-else
                        :class="'rounded-full ' + index.status[0].class"
                        @click="
                          index.status[0].slug === 'new'
                            ? selectActivity(index)
                            : showActivityDetail(index)
                        "
                        v-scroll-to="
                          index.status[0].slug === 'new'
                            ? ''
                            : { el: '#activity-detail', offset: -100 }
                        "
                      ></span>
                      <vx-card
                        :class="
                          key === streams.length - 1 ||
                          key === streams.length - 2 ||
                          key === streams.length - 3 ||
                          ckey === coreSkills.length - 1
                            ? key < 3
                              ? ''
                              : ''
                            : ''
                        "
                      >
                        <span class="arrow"></span>
                        <h3>{{ index.name }}</h3>
                        <p>
                          {{
                            index.description.length > 160
                              ? index.description.substr(0, 160) + "..."
                              : index.description
                          }}
                        </p>
                        <ul>
                          <li>
                            <img
                              :src="
                                index.coreSkill.length &&
                                index.coreSkill[0].icon
                                  ? index.coreSkill[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.waysOfLearning.length &&
                                index.waysOfLearning[0].icon
                                  ? index.waysOfLearning[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.streams.length && index.streams[0].icon
                                  ? index.streams[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                        </ul>
                      </vx-card>
                    </li>
                  </ul>
                </td>
              </template>
            </tr>
          </template>
        </table>
        <table
          class="table activity-list-table"
          width="100%"
          v-if="displayView === 'List'"
        >
          <thead>
            <tr>
              <th>Activity</th>
              <th>Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(activity, key) in activitiesList" :key="key">
              <td class="font-semibold">{{ activity.name }}</td>
              <td>
                <p>{{ activity.description }}</p>
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.coreSkill.length
                        ? activity.coreSkill[0].icon
                        : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block">
                    {{
                      activity.coreSkill.length
                        ? activity.coreSkill[0].name
                        : ""
                    }}
                  </p></span
                >
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.waysOfLearning.length
                        ? activity.waysOfLearning[0].icon
                        : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block">
                    {{
                      activity.waysOfLearning.length
                        ? activity.waysOfLearning[0].name
                        : ""
                    }}
                  </p>
                </span>
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.streams.length ? activity.streams[0].icon : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block">
                    {{
                      activity.streams.length ? activity.streams[0].name : ""
                    }}
                  </p></span
                >
              </td>
              <td>
                <template v-if="activity.parentActivityId">
                  <span
                    :class="
                      activity.isCompleted
                        ? 'rounded-full complete'
                        : 'rounded-full follow-up'
                    "
                    style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 3px;"
                  ></span>
                  {{ activity.isCompleted ? "Complete" : "Follow up" }}
                </template>
                <template v-else>
                  <span
                    :class="'rounded-full ' + activity.status[0].class"
                    style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 3px;"
                  >
                  </span
                  >{{ activity.status[0].name }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
    <activity-create-form
      v-if="activityFormDisplay"
      v-show="activityFormDisplay === true"
      id="create-form"
      :startOfWeek="startDate"
      :endOfWeek="endDate"
      :isSpontaneous="isSpontaneous"
      @refreshActivities="getActivities"
    >
    </activity-create-form>
    <div id="activity-detail">
      <activity-detail-page
        v-if="showActivityDetailPage"
        :selected-activity="selectedActivity"
      >
      </activity-detail-page>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Vue from "vue";
import moment from "moment";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import ActivityCreateForm from "../activity/ActivityCreateFrom";
import VueScrollTo from "vue-scrollto";
// import ToggleSwitch from "vuejs-toggle-switch";
import DatePicker from "vue2-datepicker";
import ActivityDetailPage from "../activity/ActivityDetailPage";

require("../../../../assets/scss/datepicker.scss");

Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.use(VueScrollTo);
// Vue.use(ToggleSwitch);

export default {
  components: { ActivityDetailPage, ActivityCreateForm, DatePicker },
  data() {
    return {
      week: new Date(),
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      dateRange: this.startOfWeek() + " - " + this.endOfWeek(),
      pickerDateRange: "",
      startDate: this.startOfWeek(),
      endDate: this.endOfWeek(),
      activitiesList: "",
      values: [
                { label: 'Matrix', value: 'Matrix' },
                { label: 'List', value: 'List' },
            ],
      activityFormDisplay: false,
      showActivityDetailPage: false,
      displayView: "Matrix",
      coreSkills: "",
      selectedActivity: "",
      streams: "",
      myOptions: {
        layout: {
          color: "black",
          backgroundColor: "#f6f6f6",
          borderColor: "#2c98d5",
          fontFamily: "open sans",
          fontWeight: "normal",
          squareCorners: false,
          noBorder: false
        },
        size: {
          fontSize: 1,
          padding: 2,
          width: 20
        },
        items: {
          delay: 0.4,
          preSelected: "Matrix",
          disabled: false,
          labels: [
            { name: "Matrix", color: "white", backgroundColor: "#2c98d5" },
            { name: "List", color: "white", backgroundColor: "#2c98d5" }
          ]
        }
      }
    };
  },
  methods: {
    ...mapActions("coreSkill", ["fetchCoreSkills"]),
    ...mapActions("activity", ["fetchActivities","setDateRange"]),
    ...mapActions("stream", ["fetchStreams"]),
    ...mapActions("waysOfLearning", ["fetchWaysOfLearning"]),
    showActivityDetail(activity) {
      this.selectedActivity = activity;
      this.showActivityDetailPage = true;
    },
    async selectActivity(activity) {
      await this.setDateRange(this.dateRange);
      this.$emit("selectActivity", activity);
    },
    goToActivity(activity) {
      this.$router.push({
        path: "/teacher/learning-story/activity/" + activity._id
      });
    },
    pickerChange(value) {
      let sDate = this.startOfWeek(value, true);
      let eDate = this.endOfWeek(value, true);

      this.dateRange = sDate + " - " + eDate;
      this.pickerDateRange = sDate + " - " + eDate;
      this.getActivities(sDate, eDate);
    },
    previousWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }

      this.getActivities(this.startDate, this.endDate);
    },
    nextWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }
      this.getActivities(this.startDate, this.endDate);
    },
    activityForm() {
      this.activityFormDisplay = !this.activityFormDisplay;
    },
    startOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);
      let diff =
        date.getDate() -
        date.getDay() +
        (date.getDay() === 0 ? -7 : 1) +
        (fromPicker ? 1 : 0);
      this.week = date;

      return this.moment(date.setDate(diff)).format("MMMM DD YYYY");
      //return new Date(date.setDate(diff)).toISOString().slice(0, 10);
    },
    endOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);

      let lastDate =
        date.getDate() - (date.getDay() - 1) + 6 + (fromPicker ? 1 : 0);

      return this.moment(date.setDate(lastDate)).format("MMMM DD YYYY");
      //return new Date(date.setDate(lastDate)).toISOString().slice(0, 10);
    },
    moment(date) {
      return moment(date);
    },
    async getActivities(
      startDate,
      endDate
    ) {
      let param =
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&roomId=" +
        this.teacher.roomId
        +
        "&planActivity=" +
        true;
      this.$vs.loading();

      this.fetchActivities(param)
        .then(res => {
          this.$vs.loading.close();
          this.activitiesList = this.$store.state.activity.activities;
        })
        .catch(err => {
          this.$vs.loading.close();
          console.error(err);
        });
      this.$vs.loading.close();
    },
    activityMatrix(coreSkillId, streamId) {
      return lodash.filter(this.activitiesList, {
        coreSkills: coreSkillId,
        stream: streamId
      });
    }
  },
  mounted() {
    let self = this;
    if (this.$store.state.activity.dateRange) {
      this.dateRange = this.$store.state.activity.dateRange;
      this.pickerDateRange = this.$store.state.activity.dateRange;
      const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
      this.startDate = new Date(pickerStartDate);
      this.endDate = new Date(pickerEndDate);
      console.log('this.startDate =---------------- ', this.startDate);
      this.getActivities(this.startDate, this.endDate);
    } else {
      setTimeout(function () {
        self.getActivities(self.startOfWeek(), self.endOfWeek());
      }, 300);
    }
  },
  created() {
    this.fetchCoreSkills()
      .then(res => {
        this.coreSkills = this.$store.state.coreSkill.coreSkills;
      })
      .catch(err => {
        console.error(err);
      });

    this.fetchStreams().then(res => {
      this.streams = this.$store.state.stream.streams;
    });

    this.fetchWaysOfLearning();
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    }
  }
};
</script>
<style lang="scss">
.new {
  background: #76c68f;
}

.complete {
  background: #d1d1d1;
}

.follow-up {
  background: #f3d16c;
}

.spontaneous {
  background: #28388d;
}

.toggle-switch {
  margin-right: 30px;
}
</style>

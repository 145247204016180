<template>
  <div>
    <vx-card class="mb-base">
      <div class="flex flex-wrap">
        <h2>Children's Activities</h2>
        <div class="ml-auto mb-8">
          <span class="toggle-switch">
              <RadioToggleButtons
                v-model='displayView'
                :values='values'
                color='#2c98d5'
                textColor='#000'
                selectedTextColor='#fff'
              />
            </span>

          <span>
            <vs-button
              color="primary"
              type="border"
              class="btn-blue-border"
              @click="activityForm"
              v-scroll-to="
                activityFormDisplay ? { el: '#create-form', offset: -100 } : ''
              "
              >Add activity</vs-button
            >
          </span>
        </div>
      </div>

      <!-- Five columns -->
      <vs-row class="bg-grid-color mb-4">
        <vs-col
          vs-type="flex"
          vs-lg-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <div class="flex datepicker-wrap">
            <span class="btn btn-outline-primary" @click="previousWeek"
              ><i class="feather icon-chevron-left" ></i
            ></span>
            <span class="reportrange-text">
              <date-picker
                v-model="week"
                type="week"
                placeholder="Select week"
                prefix-class="xmx"
                ref="picker"
                @change="pickerChange"
                :lang="lang"
                :clearable="false"
                :show-week-number="false"
              >
                <template v-slot:input="{ emit }">{{ dateRange }}</template>
              </date-picker>
            </span>
            <span class="btn btn-outline-primary" @click="nextWeek"
              ><i class="feather icon-chevron-right" ></i
            ></span>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <ul class="ml-auto status-list">
            <li>
              <span class="rounded-full" style="background: #76c68f;"></span>
              <span class="font-regular">New</span>
            </li>
            <li>
              <span class="rounded-full" style="background: #F3D16C;"></span>
              <span class="font-regular">Follow up</span>
            </li>
            <li>
              <span class="rounded-full" style="background: #d1d1d1;"></span>
              <span class="font-regular">Complete</span>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <div class="overflow-auto">
        <table
          class="table children-activity-table"
          width="100%"
          v-if="displayView === 'Matrix'"
        >
          <template v-for="(stream, key) in streams">
            <tr v-if="key === 0" :key="key">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0 && key === 0" :key="ckey"></td>
                <td v-if="key === 0" class="text-center" :key="ckey + 0.1">
                  <span class="img-block"
                    ><img
                      :src="skill.icon ? skill.icon : $defaultImage"
                      alt=""
                      class="product-img text-center"
                    />
                  </span>
                  {{ skill.name }}
                </td>
                <td
                  v-if="ckey > 0 && key > 0"
                  class="text-center"
                  :key="ckey + 0.2"
                ></td>
              </template>
            </tr>
            <tr :key="key + 0.2">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0" class="text-center" :key="ckey">
                  <span class="img-block">
                    <img
                      :src="stream.icon ? stream.icon : $defaultImage"
                      alt=""
                      class="product-img"
                    />
                  </span>
                  {{ stream.name }}
                </td>
                <td class="text-center" :key="ckey + 0.2">
                  <ul class="status-list">
                    <li
                      v-for="(index, aKey) in activityMatrix(
                        skill._id,
                        stream._id
                      )"
                      :key="aKey"
                      style="display: inline-block"
                    >
                      <span
                        v-if="index.parentActivityId"
                        @click="
                          index.isCompleted
                            ? showActivityDetail(index)
                            : activityEdit(index.parentActivityId, index)
                        "
                        :class="
                          index.isCompleted
                            ? 'rounded-full complete'
                            : 'rounded-full follow-up'
                        "
                        v-scroll-to="
                          index.isCompleted
                            ? { el: '#activity-detail', offset: -100 }
                            : ''
                        "
                      ></span>
                      <span
                        v-else
                        :class="'rounded-full ' + index.status[0].class"
                        @click="
                          index.status[0].slug === 'new'
                            ? activityEdit(index._id)
                            : showActivityDetail(index)
                        "
                        v-scroll-to="
                          index.status[0].slug === 'new'
                            ? { el: '#edit-form', offset: -100 }
                            : { el: '#activity-detail', offset: -100 }
                        "
                      ></span>
                      <vx-card
                        :class="
                          key === streams.length - 1 ||
                          key === streams.length - 2 ||
                          key === streams.length - 3 ||
                          ckey === coreSkills.length - 1
                            ? key < 3
                              ? 'vx-card-down'
                              : 'vx-card-up'
                            : ''
                        "
                      >
                        <span class="arrow"></span>

                        <h3>{{ index.name }}</h3>
                        <p>
                          {{
                            index.description.length > 160
                              ? index.description.substr(0, 160) + "..."
                              : index.description
                          }}
                        </p>
                        <ul>
                          <li>
                            <img
                              :src="
                                index.coreSkill.length &&
                                index.coreSkill[0].icon
                                  ? index.coreSkill[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.waysOfLearning.length &&
                                index.waysOfLearning[0].icon
                                  ? index.waysOfLearning[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.streams.length && index.streams[0].icon
                                  ? index.streams[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                        </ul>
                      </vx-card>
                    </li>
                  </ul>
                </td>
              </template>
            </tr>
          </template>
        </table>
        <table
          class="table activity-list-table"
          width="100%"
          v-if="displayView === 'List'"
        >
          <thead>
            <tr>
              <th>Activity</th>
              <th>Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(activity, key) in activitiesList" :key="key">
              <td class="font-semibold">
                <span
                  style="cursor: pointer;"
                  @click="
                    activity.status[0].slug === 'new'
                      ? activityEdit(activity._id)
                      : ''
                  "
                  v-scroll-to="{ el: '#edit-form', offset: -100 }"
                >
                  {{ activity.name }}
                </span>
              </td>
              <td>
                <p>{{ activity.description }}</p>
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.coreSkill.length
                        ? activity.coreSkill[0].icon
                        : ''
                    "
                    alt=""
                    width="45px"
                    height="45px"
                    class="inline-block"
                  />
                  <p class="block">
                    {{
                      activity.coreSkill.length
                        ? activity.coreSkill[0].name
                        : ""
                    }}
                  </p></span
                >
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.waysOfLearning.length
                        ? activity.waysOfLearning[0].icon
                        : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block">
                    {{
                      activity.waysOfLearning.length
                        ? activity.waysOfLearning[0].name
                        : ""
                    }}
                  </p></span
                >
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.streams.length ? activity.streams[0].icon : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block">
                    {{
                      activity.streams.length ? activity.streams[0].name : ""
                    }}
                  </p></span
                >
              </td>
              <td>
                <template v-if="activity.parentActivityId" class="status-list">
                  <span
                    :class="
                      'rounded-full ' + activity.isCompleted
                        ? 'complete'
                        : 'follow-up'
                    "
                    style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 3px;"
                  ></span>
                  {{ activity.isCompleted ? "Complete" : "Follow Up" }}
                </template>
                <template v-else class="status-list">
                  <span
                    :class="'rounded-full ' + activity.status[0].class"
                    style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 3px;"
                  >
                  </span
                  >{{ activity.status[0].name }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
    <div id="create-form">
      <activity-create-form
        v-if="activityFormDisplay"
        v-show="activityFormDisplay === true"
        :selected-activity="selectedActivity"
        :startOfWeek="startDate"
        :endOfWeek="endDate"
        @refreshActivities="getActivities"
      >
      </activity-create-form>
    </div>
    <div id="edit-form">
      <activity-edit-form
        v-if="activityEditFormDisplay === true"
        :selected-activity="selectedActivity"
        @editActivity="editActivity"
        :follow-up-data="followUpData"
      ></activity-edit-form>
    </div>
    <div id="activity-detail">
      <activity-detail-page
        v-if="showActivityDetailPage === true"
        :selected-activity="selectedActivity"
      >
      </activity-detail-page>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Vue from "vue";
import moment from "moment";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import ActivityCreateForm from "../activity/ActivityCreateFrom";
import ActivityEditForm from "../activity/ActivityEditForm";
import DatePicker from "vue2-datepicker";
import ActivityDetailPage from "../activity/ActivityDetailPage";

require("../../../../assets/scss/datepicker.scss");
Vue.use(VueLodash, { name: "custom", lodash: lodash });
// import ToggleSwitch from "vuejs-toggle-switch";

const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
// Vue.use(ToggleSwitch);

export default {
  components: {
    ActivityDetailPage,
    ActivityCreateForm,
    ActivityEditForm,
    DatePicker
  },
  data() {
    return {
      followUpData: "",
      week: new Date(),
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      dateRange: this.startOfWeek() + " - " + this.endOfWeek(),
      pickerDateRange: "",
      startDate: this.startOfWeek(),
      endDate: this.endOfWeek(),
      activitiesList: "",
      activityFormDisplay: false,
      displayView: "Matrix",
      activityEditFormDisplay: false,
      showActivityDetailPage: false,
      selectedActivity: "",
      popupActive: false,
      values: [
                { label: 'Matrix', value: 'Matrix' },
                { label: 'List', value: 'List' },
            ],
      myOptions: {
        layout: {
          color: "black",
          backgroundColor: "rgb(246, 246, 246)",
          borderColor: "#2c98d5",
          fontFamily: "open sans",
          fontWeight: "normal",
          // fontWeightSelected: 'bold',
          squareCorners: false,
          noBorder: false
        },
        size: {
          fontSize: 1,
          padding: 2,
          width: 20
        },
        items: {
          delay: 0.4,
          preSelected: "Matrix",
          disabled: false,
          labels: [
            { name: "Matrix", color: "white", backgroundColor: "#2898D5" },
            { name: "List", color: "white", backgroundColor: "#2898D5" }
          ]
        }
      }
    };
  },
  props: ["selectedFromPlan"],
  methods: {
    ...mapActions("activity", ["fetchActivities", "fetchActivityById", "setDateRange"]),
    showActivityDetail(activity) {
      this.selectedActivity = activity;
      this.activityEditFormDisplay = false;
      this.activityFormDisplay = false;
      this.showActivityDetailPage = true;
    },
    pickerChange(value) {
      let sDate = this.startOfWeek(value, true);
      let eDate = this.endOfWeek(value, true);

      this.dateRange = sDate + " - " + eDate;
      this.pickerDateRange = sDate + " - " + eDate;
      this.getActivities(sDate, eDate);
    },
    previousWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }

      this.getActivities(this.startDate, this.endDate);
    },
    nextWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }

      this.getActivities(this.startDate, this.endDate);
    },
    activityForm() {
      this.showActivityDetailPage = false;
      this.selectedActivity = null;
      this.activityFormDisplay = !this.activityFormDisplay;
      this.activityEditFormDisplay = false;
    },
    startOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);
      let diff =
        date.getDate() -
        date.getDay() +
        (date.getDay() === 0 ? -6 : 1) +
        (fromPicker ? 1 : 0);
      this.week = date;
      return this.moment(date.setDate(diff)).format("MMMM DD YYYY");
      // return new Date(date.setDate(diff))
      //   .toISOString()
      //   .slice(0, 10)
      //   .split("-")
      //   .join("/");
    },
    endOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);

      let lastDate =
        date.getDate() - (date.getDay() - 1) + 6 + (fromPicker ? 1 : 0);

      return this.moment(date.setDate(lastDate)).format("MMMM DD YYYY");

      //return new Date(date.setDate(lastDate)).toISOString().slice(0, 10);
    },
    moment(date) {
      return moment(date);
    },
    async getActivities(
      startDate = this.startOfWeek(),
      endDate = this.endOfWeek()
    ) {
      let param =
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&roomId=" +
        this.teacher.roomId;
      this.$vs.loading();

      this.fetchActivities(param)
        .then(res => {
          this.$vs.loading.close();
          this.activitiesList = this.$store.state.activity.activities;
        })
        .catch(err => {
          this.$vs.loading.close();
          console.error(err);
        });
    },
    activityMatrix(coreSkillId, streamId) {
      return lodash.filter(this.activitiesList, {
        coreSkills: coreSkillId,
        stream: streamId
      });
    },
    async activityEdit(activityId, followUp = null) {
      this.activityFormDisplay = false;
      this.activityEditFormDisplay = false;
      if (followUp) {
        this.followUpData = followUp;
      }
      this.$vs.loading();
      await this.fetchActivityById(activityId).then(res => {
        this.$vs.loading.close();
        this.selectedActivity = this.$store.state.activity.activity;
      });
      this.activityEditFormDisplay = true;
      if (followUp) {
        let self = this;
        setTimeout(function() {
          self.$scrollTo("#children-follow-up");
        }, 500);

        setTimeout(function() {
          self.followUpData = "";
        }, 1000);
      }
    },
    editActivity(activity) {
      this.showActivityDetailPage = false;
      this.activityFormDisplay = false;
      this.activityEditFormDisplay = false;
      this.activityFormDisplay = true;
      this.selectedActivity = activity;
    }
  },
  mounted() {
    let self = this;
          console.log(self.selectedFromPlan,"Learning stories")

    if (self.selectedFromPlan) {
      if ("parentActivityId" in self.selectedFromPlan) {
        self.followUpData = self.selectedFromPlan;
        self.activityEdit(self.selectedFromPlan.parentActivityId);
        setTimeout(function() {
          self.$scrollTo("#children-follow-up");
        }, 500);
      } else {
        self.activityEdit(self.selectedFromPlan._id);
        self.$scrollTo("#edit-form");
      }
    }
    if (this.$store.state.activity.dateRange) {
      this.dateRange = this.$store.state.activity.dateRange;
      this.pickerDateRange = this.$store.state.activity.dateRange;
      const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
      this.startDate = new Date(pickerStartDate);
      this.endDate = new Date(pickerEndDate);
      this.setDateRange("");
      this.getActivities(this.startDate, this.endDate);
    } else {
      this.getActivities(self.startOfWeek(), self.endOfWeek());
    }
  },
  computed: {
    coreSkills() {
      return this.$store.state.coreSkill.coreSkills;
    },
    streams() {
      return this.$store.state.stream.streams;
    },
    teacher() {
      return this.$store.state.AppActiveUser;
    }
  }
};
</script>
<style lang="scss">
.new {
  background: #76c68f;
}

.complete {
  background: #d1d1d1;
}

.follow-up {
  background: #f3d16c;
}

.fast-check-popup.fullscreen {
  .h2 {
    margin-bottom: 0;
  }

  .h3 {
    color: #222222;
  }

  .overflow-auto {
    overflow: hidden !important;
    overflow-x: auto !important;
  }

  .vs-popup {
    width: 75%;

    .vs-popup--content {
      padding: 30px;
    }

    .vs-popup--header {
      height: 0;

      .vs-popup--title {
        h3 {
          display: none;
        }
      }
    }

    .vs-popup--close {
      transform: none !important;
      background: none !important;
      box-shadow: none;
      position: relative;
      top: 35px;
      right: 20px;
      font-size: 25px;
      color: #2b2d3d;

      &:hover {
        color: #2d9ad5;
      }
    }
  }
}

.fastcheck-list {
  display: flex;
  margin: 30px 0 20px;
  border-bottom: 1px solid #d8d8d8;
  width: fit-content;
  min-width: 100%;

  li {
    vertical-align: top;
    position: relative;
    min-width: 200px;
    width: 200px;
    max-width: none;
    text-align: center;
    margin-right: 0;
    padding: 0 5px;
    @media (min-width: 1480px) {
      padding: 0 30px;
      margin: 0 -10px;
    }

    &:after {
      content: "";
      background-image: url("../../../../assets/images/personhood/line.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: absolute;
      height: 30px;
      bottom: -22px;
      left: 0;
      width: 100% !important;
      opacity: 0;
    }

    span {
      font-size: 16px;
      line-height: 22px;
      color: #606060;
      display: block;
      text-align: center;
      margin-bottom: 12px;
      @media (min-width: 1640px) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    &.active {
      &:after {
        opacity: 1;
      }

      .con-vs-avatar.large {
        border: none;
      }

      span {
        font-weight: 600;
        color: #289ad5;
      }
    }
  }
}

.rank-title {
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 16px;
  line-height: 30px;
  @media (min-width: 1480px) {
    font-size: 18px;
    line-height: 36px;
  }

  li {
    display: inline-flex;
    align-items: center;
    margin-right: 40px;

    .tag {
      margin-right: 15px;
    }
  }
}

.tag {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: inline-block;


  &.average-tag {
    background: #74cac2;
  }

  &.trend-tag {
    background: #27378e;
  }
}

.rank-col {
  margin-top: 30px;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 20px;
  @media (min-width: 901px) {
    padding-right: 50px;
    font-size: 18px;
    line-height: 25px;
  }
  @media (min-width: 1480px) {
    padding-right: 250px;
    font-size: 20px;
    line-height: 27px;
  }
}

.rank-bar {
  background: #ededed;
  height: 6px;
  border-radius: 3px;
  display: flex;
  width: 100%;
  margin: 30px 0 0;
  @media (min-width: 901px) {
    margin: 50px 0;
  }

  li {
    width: calc(100% / 4);
    position: relative;

    .tag {
      position: absolute;
      top: -8px;
      right: 0;
    }
  }
}

.rank-link-col {
  .font-semibold {
    text-decoration: underline;
    font-size: 16px;
    line-height: 22px;
    color: #289ad5;
    margin-top: 20px;
    @media (min-width: 1480px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.fast-check-popup1 {
  .vs-popup {
    .vs-popup--content {
      padding-top: 0 !important;
    }

    .vs-popup--close {
      z-index: 20;
    }
  }

  .vx-card {
    box-shadow: none !important;

    .vx-card__body {
      padding: 0 !important;
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0 40px;
  }

  .range {
    width: 100%;
  }
}

.toggle-switch {
  margin-right: 30px;
}
</style>

<template>
  <div>
    <vx-card class="mb-base" style="margin-top: 20px;">
      <div class="flex items-center mb-5">
        <h2 class="mb-0">Activities</h2>

        <span class="ml-auto">
          <vs-button
            color="primary"
            class="mr-5"
            @click="editActivity(activity)"
            type="filled"
            >Edit</vs-button
          >
          <feather-icon
            v-if="isFavourite === true"
            icon="HeartIcon"
            svgClasses="fill-current text-primary"
            @click="updateFavouriteStatus"
          />
          <feather-icon
            v-else
            icon="HeartIcon"
            svgClasses=" text-primary"
            @click="updateFavouriteStatus"
          />
        </span>
      </div>
      <div class="overflow-auto">
        <table class="table learning-activity-table" width="100%">
          <thead>
            <tr>
              <th>Activity</th>
              <th>Description</th>
              <th class="text-center">Core Skill</th>
              <th class="text-center">Ways of Learning</th>
              <th class="text-center">STREAM</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{{ activity.name }}</th>
              <td>{{ activity.description }}</td>
              <td class="text-center">
                <img
                  v-if="activity.coreSkills"
                  :src="
                    activity.coreSkills[0].icon
                      ? activity.coreSkills[0].icon
                      : $defaultImage
                  "
                  alt
                  class="inline-block h-12 object-contain"
                />
                <p>
                  {{ activity.coreSkills ? activity.coreSkills[0].name : "" }}
                </p>
              </td>
              <td class="text-center">
                <img
                  v-if="activity.waysOfLearnings"
                  :src="
                    activity.waysOfLearnings[0].icon
                      ? activity.waysOfLearnings[0].icon
                      : $defaultImage
                  "
                  alt
                  class="inline-block h-12 object-contain"
                />
                <p>
                  {{
                    activity.waysOfLearnings
                      ? activity.waysOfLearnings[0].name
                      : ""
                  }}
                </p>
              </td>
              <td class="text-center">
                <img
                  v-if="activity.streams"
                  :src="
                    activity.streams[0].icon
                      ? activity.streams[0].icon
                      : $defaultImage
                  "
                  alt
                  class="inline-block h-12 object-contain"
                />
                <p>{{ activity.streams ? activity.streams[0].name : "" }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>

    <!--Purpose Statement-->
    <vx-card class="mb-base">
      <h2>Play with Purpose. (Your Intentionality)</h2>
      <vs-textarea
        :class="purposeStatement ? 'hasValue' : ''"
        :label="'Play with purpose statement'"
        v-model="purposeStatement"
        style="font-size: 16px; line-height: 23px; border: 1px solid #d8d8d8;"
      />
    </vx-card>

    <!--Photos-->
    <vx-card class="mb-base photo-wrapper">
      <h2>Photos and Videos</h2>
      <ul class="photos-list">
        <li
          class="photo-item"
          v-for="(image, index) in media"
          :key="index"
          style="display: inline-block"
        >
          <img
            v-if="hasAllowedVideoExtension(image.src)"
            @click="openGallery(image, index)"
            :src="convertVideoFileExtenion(image.src)"
          />
          <img
            v-if="hasAllowedVideoExtension(image.src)"
            class="playButton"
            style="max-height: 45px"
            @click="openGallery(image, index)"
            src="https://personhood.s3.ap-southeast-2.amazonaws.com/undefined/play-button-svgrepo-com.svg"
          />

          <img
            v-else
            v-lazy="image.src || image.thumb"
            style="height: 100px"
            @click="openGallery(image, index)"
          />

          <feather-icon
            icon="XIcon"
            class="m-0 cursor-pointer"
            @click="removeImage(image.id)"
          />
        </li>
      </ul>
      <LightBox
        v-if="this.openLightBox"
        ref="lightbox"
        :media="media"
        :show-light-box="false"
        :show-caption="true"
        :show-thumbs="false"
        @onImageChanged="onImageChanged"
      />
      <vs-popup
        class="holamundo add-children-popup"
        id="tag-children"
        title
        :active.sync="tagChildrenPopUp"
      >
        <h2 class="h1 mb-6">Tag Children</h2>
        <form>
          <vx-card class="mx-0">
            <vs-row>
              <vs-col vs-w="12">
                <ul>
                  <li>
                    <div
                      class="round"
                      style="display: flex; align-items: center"
                    >
                      <input
                        :id="'checkbox-all'"
                        type="checkbox"
                        ref="checkboxAllTag"
                        @click="selectAllTagChildren(toggle)"
                      />
                      <label :for="'checkbox-all'">
                        <i class="feather icon-check"></i>
                      </label>
                      <span
                        style="margin-left: 23px; font-size: 16px; line-height: 22px;"
                        >Select All</span
                      >
                    </div>
                  </li>
                  <li v-for="(option, key) in tagChildrenOptions" :key="key">
                    <div
                      class="round"
                      style="display: flex; align-items: center"
                    >
                      <input
                        :id="'checkbox-tag-' + key"
                        name="selectedChildren"
                        type="checkbox"
                        v-model="taggedTo"
                        :value="option"
                      />

                      <label :for="'checkbox-tag-' + key">
                        <i class="feather icon-check"></i>
                      </label>
                      <vs-avatar
                        size="46px"
                        :src="option.photo"
                        style="margin-left: 22px;"
                      />
                      <span
                        style="margin-left: 6px; font-size: 16px; line-height: 22px;"
                        >{{ option.label }}</span
                      >
                    </div>
                  </li>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('selectedChildren')"
                    >{{ errors.first("selectedChildren") }}</span
                  >
                </ul>
              </vs-col>
            </vs-row>
            <span
              class="text-danger text-sm"
              v-show="errors.has('taggedChildren')"
              >{{ errors.first("taggedChildren") }}</span
            >
          </vx-card>
          <vs-button
            @click="confirmTag"
            color="primary"
            class="mt-5"
            type="filled"
            >Confirm Tag</vs-button
          >
          <vs-button
            @click="cancelTagChildren"
            color="danger"
            class="mt-5 ml-5"
            type="filled"
            >Cancel
          </vs-button>
        </form>
      </vs-popup>

      <input
        type="file"
        class="hidden"
        name="file"
        ref="uploadImgInput"
        @change="uploadImage($event.target.files)"
        accept="image, video"
        multiple
      />
      <a
        href="javascript:;"
        @click="$refs.uploadImgInput.click()"
        class="vs-button vs-button-primary vs-button-border btn-blue-border"
      >
        Upload photos and videos
      </a>
    </vx-card>

    <!--EYLF Checklist-->
    <vx-card class="mb-base eylf-card">
      <div class="flex mb-5">
        <vs-button class="ml-auto" @click="expandCollapseAll">
          {{ collapsed ? "Expand " : "Collapse " }} all</vs-button
        >
      </div>
      <vs-row>
        <vs-col
          vs-lg="4"
          vs-sm="12"
          vs-xs="12"
          class="eylf-col px-0 md:pl-5 md:pr-10 border-b-0 mb-5 md:mb-0"
          v-for="(category, key) in eylfCategories"
          :key="key"
        >
          <h2>Learning Framework {{ category.title }}</h2>
          <vs-collapse class="p-0">
            <template v-for="(list, index) in category.eylfChecklists">
              <div class="flex align-self-start mb-5" :key="index">
                <vs-checkbox
                  v-model="selectedChecklist"
                  :vs-value="list._id"
                ></vs-checkbox>
                <collapse
                  ref="collapsible"
                  :key="index"
                  :collapse="collapsed"
                  :data="list"
                ></collapse>
              </div>
            </template>
          </vs-collapse>
        </vs-col>
      </vs-row>
    </vx-card>

    <!--Children-->
    <vx-card class="mb-base" style="padding-bottom: 20px;">
      <h2>Children</h2>
      <vs-row>
        <vs-button
          @click="showChildPopUp"
          color="primary"
          class="pull-right btn-blue-border"
          type="border"
          style="position: absolute; bottom: 0; right: 20px; z-index: 1;"
          >Add/ remove Children
        </vs-button>
        <vs-col vs-type="flex" style="position: relative; min-height: 60px;">
          <template>
            <ul class="teacher-list w-full">
              <li
                v-for="(child, key) in children"
                :class="key === activeChildIndex ? 'active' : ''"
                @click="selectChildToUpdate(child, key)"
                style="vertical-align: top"
                :key="key + 0.3"
              >
                <vs-avatar size="large" :src="child.photo" />
                <h3
                  class="h5"
                  style="color: #289AD5; font-size: 14px; line-height: 18px;"
                >
                  {{ child.name }}
                </h3>
              </li>
            </ul>
          </template>
        </vs-col>
      </vs-row>
    </vx-card>

    <vx-card class="mb-base" v-if="children.length">
      <h3 class="h2 mb-4">
        How has {{ children[activeChildIndex].name }} changed? (Assessment of
        Learning)
      </h3>
      <vs-row class="textarea1">
        <vs-col
          :class="children[activeChildIndex].howHasChanged ? 'hasValue' : ''"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="8"
          vs-sm="12"
          vs-xs="12"
          style="margin-bottom: 15px;"
        >
          <vs-textarea
            :label="'How has ' + children[activeChildIndex].name + ' changed'"
            v-model="children[activeChildIndex].howHasChanged"
          />
        </vs-col>
      </vs-row>
      <vs-row class="mb-6 textarea2">
        <vs-col
          :class="
            children[activeChildIndex].coreSkillReflection ? 'hasValue' : ''
          "
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="6"
          vs-xs="12"
          class="pr-4"
        >
          <vs-textarea
            label="Core skill reflection"
            v-model="children[activeChildIndex].coreSkillReflection"
          />
        </vs-col>
        <vs-col
          :class="children[activeChildIndex].childVoice ? 'hasValue' : ''"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="6"
          vs-xs="12"
          class="pl-4"
        >
          <vs-textarea
            label="Child's voice"
            v-model="children[activeChildIndex].childVoice"
          />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-w="10">
          <h3 class="h2 mb-4">
            Observed growth in {{ activity.coreSkills[0].name }}
          </h3>
        </vs-col>
      </vs-row>
      <vs-row class="mt-8">
        <div class="range">
          <ul class="range-labels">
            <li>
              <input
                id="one"
                type="radio"
                name="growthInSafety"
                v-model="children[activeChildIndex].growthInSafety"
                value="1"
              />
              <label for="one">
                <span></span>
                1
              </label>
            </li>
            <li>
              <input
                id="two"
                type="radio"
                name="growthInSafety"
                v-model="children[activeChildIndex].growthInSafety"
                value="2"
              />
              <label for="two">
                <span></span>
                2
              </label>
            </li>
            <li>
              <input
                id="three"
                type="radio"
                name="growthInSafety"
                v-model="children[activeChildIndex].growthInSafety"
                value="3"
              />
              <label for="three">
                <span></span>
                3
              </label>
            </li>
            <li>
              <input
                id="four"
                type="radio"
                name="growthInSafety"
                v-model="children[activeChildIndex].growthInSafety"
                value="4"
              />
              <label for="four">
                <span></span>
                4
              </label>
            </li>
            <li>
              <input
                id="five"
                type="radio"
                name="growthInSafety"
                v-model="children[activeChildIndex].growthInSafety"
                value="5"
              />
              <label for="five">
                <span></span>
                5
              </label>
            </li>
          </ul>
        </div>
      </vs-row>
      <vs-row>
        <div class="m-10"></div>
      </vs-row>
    </vx-card>
    <div id="children-follow-up"></div>
    <vx-card v-if="children.length">
      <div class="flex mb-5">
        <h3 class="h2 mb-0">Follow Up Activity</h3>
        <vs-button
          color="primary"
          type="border"
          class="ml-auto btn-blue-border"
          @click="addChildFollowUp(children[activeChildIndex])"
          v-if="children[activeChildIndex].followUps.length < 1"
          >Add Follow Up
        </vs-button>
      </div>
      <div class="overflow-auto">
        <table
          class="table border-none follow-activity-table"
          width="100%"
          v-if="children[activeChildIndex].followUps.length > 0"
        >
          <template
            v-for="(followUp, fKey) in children[activeChildIndex].followUps"
          >
            <tr v-bind:key="fKey + 0.11">
              <td width="40%">
                <!-- <strong class="block">Activity {{ fKey + 1 }} :</strong> -->
                <strong class="block mb-2">{{ followUp.name }}</strong>
                <p>{{ followUp.description }}</p>
              </td>
              <!-- <td width="30%">{{ followUp.description }}</td> -->
              <td width="10%" class="text-center">
                <strong class="block mb-5">Core skill</strong>
                <img
                  v-if="followUp.coreSkills"
                  :src="followUp.coreSkills[0].icon"
                  alt
                  class="inline-block h-12 object-contain"
                />
                <span class="block">{{
                  followUp.coreSkills ? followUp.coreSkills[0].name : ""
                }}</span>
              </td>
              <td width="10%" class="text-center">
                <strong class="block mb-5">Way of Learning</strong>
                <img
                  v-if="followUp.waysOfLearnings"
                  :src="followUp.waysOfLearnings[0].icon"
                  alt
                  width="45px"
                  height="45px"
                  class="inline-block"
                  style="object-fit: scale-down;"
                />
                <span class="block">{{
                  followUp.waysOfLearnings
                    ? followUp.waysOfLearnings[0].name
                    : ""
                }}</span>
              </td>
              <td width="10%" class="text-center">
                <strong class="block mb-5">STREAM</strong>
                <img
                  v-if="followUp.streams"
                  :src="followUp.streams[0].icon"
                  alt
                  width="45px"
                  height="45px"
                  class="inline-block"
                  style="object-fit: scale-down;"
                />
                <span class="block">{{
                  followUp.streams ? followUp.streams[0].name : ""
                }}</span>
              </td>
              <td>
                <!-- <vs-checkbox
                  @click="followUpComplete($event, followUp._id)"
                  v-model="followUp.isCompleted"
                  vs-value="1"
                ></vs-checkbox> -->
                <vs-textarea
                  :class="followUpCompletedText ? 'hasValue' : ''"
                  :label="'Complete text'"
                  v-model="followUpCompletedText"
                  name="followUpCompletedText"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('followUpCompletedText')"
                  >{{ errors.first("followUpCompletedText") }}</span
                >
                <!-- <vs-button
                  @click="followUpComplete(followUp._id)"
                  class="pull-right"
                >
                  {{
                    followUp.isCompleted
                      ? "Update complete text"
                      : "Complete follow up"
                  }}</vs-button> -->
              </td>
            </tr>
            <tr v-bind:key="fKey + 0.22">
              <td colspan="6" class="text-right">
                <vs-button
                  color="primary"
                  type="filled"
                  class="ml-10"
                  @click="editFollowUp(followUp, fKey)"
                  >Edit
                </vs-button>

                <vs-button
                  @click="followUpComplete(followUp._id, fKey)"
                  class="ml-10"
                >
                  {{ followUp.isCompleted ? "Update" : "Complete" }}</vs-button
                >

                <vs-button
                  color="danger"
                  type="filled"
                  class="ml-10"
                  @click="removeFollowUp(followUp._id, fKey)"
                  >Delete
                </vs-button>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </vx-card>
    <vs-row
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="left"
      class="w-full"
    >
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5" align="right">
          <vs-button
            type="filled"
            @click.prevent="makeSpontaneous"
            color="blue"
            class="mr-5 spontaneous-btn"
            >Make Spontaneous
          </vs-button>
          <vs-button
            type="filled"
            @click.prevent="submitDraft"
            color="success"
            class="mr-5"
            >Save Draft
          </vs-button>
          <vs-button type="filled" @click.prevent="submitActivity"
            >Complete Learning Stories
          </vs-button>
        </div>
      </vs-col>
    </vs-row>

    <!--Children Add popup-->
    <vs-popup
      class="holamundo add-children-popup"
      title
      :active.sync="childPopup"
    >
      <h2 class="h1 mb-6">Add Children</h2>
      <form>
        <vx-card class="mx-0">
          <vs-row>
            <vs-col vs-w="12">
              <vx-input-group
                :class="'mb-base ' + searchText ? 'hasValue' : ''"
              >
                <vs-input
                  icon-no-border
                  icon="search"
                  label-placeholder="Search"
                  v-model="searchText"
                />
              </vx-input-group>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12">
              <ul>
                <li>
                  <div class="round" style="display: flex; align-items: center">
                    <input
                      :id="'checkbox-a'"
                      type="checkbox"
                      ref="checkboxAllChildren"
                      @click="selectAllChildren(toggle)"
                    />
                    <label :for="'checkbox-a'">
                      <i class="feather icon-check"></i>
                    </label>
                    <span
                      style="margin-left: 23px; font-size: 16px; line-height: 22px;"
                      >Select All</span
                    >
                  </div>
                </li>
                <li v-for="(option, key) in options" :key="key">
                  <div class="round" style="display: flex; align-items: center">
                    <input
                      :id="'checkbox-' + key"
                      name="selectedChildren"
                      type="checkbox"
                      v-model="selectedChildren"
                      :value="option"
                    />

                    <label :for="'checkbox-' + key">
                      <i class="feather icon-check"></i>
                    </label>
                    <vs-avatar
                      size="46px"
                      :src="option.photo"
                      style="margin-left: 22px;"
                    />
                    <span
                      style="margin-left: 6px; font-size: 16px; line-height: 22px;"
                      >{{ option.label }}</span
                    >
                  </div>
                </li>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('selectedChildren')"
                  >{{ errors.first("selectedChildren") }}</span
                >
              </ul>
            </vs-col>
          </vs-row>

          <span class="text-danger text-sm" v-show="errors.has('children')">{{
            errors.first("children")
          }}</span>
        </vx-card>
        <div class="flex justify-between mt-5">
          <vs-button @click="cancelAddChildren" color="danger" type="filled"
            >Cancel</vs-button
          >
          <vs-button
            @click="addChildrenToActivity"
            color="primary"
            type="filled"
            >Add Children</vs-button
          >
        </div>
      </form>
    </vs-popup>

    <!--Follow Up Pop Up-->
    <vs-popup
      fullscreen
      class="holamundo add-follow-popup"
      title
      :active.sync="followUpPopUp"
    >
      <h2>Follow Up: {{ followUpChild.name }}</h2>
      <vx-card class="add-activity-card">
        <form>
          <h4>Core Skill</h4>
          <ul class="centerx flex-wrap">
            <li
              v-for="(index, key) in coreSkills"
              :key="key"
              style="display: inline-flex; margin-right: 20px; flex-wrap: wrap;"
            >
              <input
                type="radio"
                name="coreSkillId"
                v-model="coreSkillId"
                :value="index._id"
                v-validate="'required'"
              />
              <div class="radio-wrap">
                <div>
                  <img
                    :src="index.icon ? index.icon : $defaultImage"
                    :alt="index.name"
                    height="40px"
                    width="40px"
                  />
                  <span>{{ index.name }}</span>
                </div>
              </div>
            </li>
          </ul>
          <span
            class="text-danger text-sm"
            v-show="errors.has('coreSkillId')"
            >{{ errors.first("coreSkillId") }}</span
          >
          <br />
          <h4>Ways Of Learning</h4>

          <ul class="centerx flex-wrap">
            <li
              v-for="(index, key) in waysOfLearningList"
              :key="key"
              style="display: inline-flex;  margin-right: 20px; flex-wrap: wrap;"
            >
              <input
                type="radio"
                name="waysOfLearningId"
                v-model="waysOfLearningId"
                :value="index._id"
                v-validate="'required'"
              />
              <div class="radio-wrap">
                <div>
                  <img
                    :src="index.icon ? index.icon : $defaultImage"
                    :alt="index.name"
                    height="40px"
                    width="40px"
                  />
                  <span>{{ index.name }}</span>
                </div>
              </div>
            </li>
          </ul>
          <span
            class="text-danger text-sm"
            v-show="errors.has('waysOfLearningId')"
            >{{ errors.first("waysOfLearningId") }}</span
          >
          <br />
          <h4>STREAM</h4>

          <ul class="centerx flex-wrap">
            <li
              style="display: inline-flex;  margin-right: 20px; flex-wrap: wrap;"
              v-for="(index, key) in streams"
              :key="key"
            >
              <input
                type="radio"
                name="streamId"
                v-model="streamId"
                :value="index._id"
                v-validate="'required'"
              />
              <div class="radio-wrap">
                <div>
                  <img
                    :src="index.icon ? index.icon : $defaultImage"
                    :alt="index.name"
                    height="40px"
                    width="40px"
                  />
                  <span>{{ index.name }}</span>
                </div>
              </div>
            </li>
          </ul>
          <span class="text-danger text-sm" v-show="errors.has('streamId')">{{
            errors.first("streamId")
          }}</span>
          <br />
          <div class="vx-row mb-2">
            <div class="vx-col w-full mb-3">
              <!-- <vue-simple-suggest


            pattern="\w+"
                v-model="name"
                name="name"
                :list="getList"
                :max-suggestions="10"
                :min-length="1"
                :debounce="200"
                :filter-by-query="true"
                :prevent-submit="true"
                :controls="{
                  selectionUp: [38, 33],
                  selectionDown: [40, 34],
                  select: [13, 36],
                  hideList: [27, 35]
                }"
                :mode="'input'"
                :nullable-select="true"
                ref="suggestComponent"
                value-attribute="id"
                display-attribute="title"
                v-validate="'required'"
                @select="onSuggestSelect"
>


<div
                  :class="
                    name !== '' ? 'g input-field hasValue' : 'g input-field'
                  "
                >
                  <input
                    type="text"
                    class="vs-input--input"
                    :value="name"
                  />
                  <span class="vs-placeholder-label">Activity</span>
                </div>

                <div
                  slot="suggestion-item"
                  slot-scope="scope"
                  :title="scope.suggestion.description"
                >
                  <div class="text">
                    <span
                      v-html="
                        scope.suggestion.title +
                          ' - ' +
                          scope.suggestion.description
                      "
                    ></span>
                  </div>
                </div>
  </vue-simple-suggest>
  <p>Chosen element: {{ chosen }}</p>-->

              <vue-simple-suggest
                pattern="\w+"
                v-model="name"
                name="name"
                :list="getList"
                :max-suggestions="10"
                :min-length="1"
                :debounce="200"
                :filter-by-query="true"
                :prevent-submit="true"
                :controls="{
                  selectionUp: [38, 33],
                  selectionDown: [40, 34],
                  select: [13, 36],
                  hideList: [27, 35]
                }"
                :mode="'input'"
                :nullable-select="true"
                ref="suggestComponent"
                value-attribute="id"
                display-attribute="title"
                v-validate="'required'"
                @select="onSuggestSelect"
              >
                <div
                  :class="
                    name !== '' ? 'g input-field hasValue' : 'g input-field'
                  "
                >
                  <input type="text" class="vs-input--input" :value="name" />
                  <span class="vs-placeholder-label">Activity</span>
                </div>

                <div
                  slot="suggestion-item"
                  slot-scope="scope"
                  :title="scope.suggestion.description"
                >
                  <div class="text">
                    <span
                      v-html="
                        scope.suggestion.title +
                          ' - ' +
                          scope.suggestion.description
                      "
                    ></span>
                  </div>
                </div>

                <div
                  class="misc-item"
                  slot="misc-item-below"
                  slot-scope="{ suggestions }"
                  v-if="loading"
                >
                  <span>Loading...</span>
                </div>
              </vue-simple-suggest>

              <span class="text-danger text-sm" v-show="errors.has('name')">{{
                errors.first("name")
              }}</span>
            </div>
          </div>
          <div class="mb-2">
            <div class="w-full">
              <vs-textarea
                :class="description ? 'hasValue' : ''"
                :label="'Description'"
                v-model="description"
                name="description"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('description')"
                >{{ errors.first("description") }}</span
              >
            </div>
          </div>

          <vs-button
            type="filled"
            @click.prevent="submitFollowUp"
            class="mt-5 block ml-auto btn-blue-border"
            >Add Activity
          </vs-button>
        </form>
      </vx-card>
    </vs-popup>

    <!--Fast Check Pop Up-->
    <vs-popup
      fullscreen
      v-if="fastCheckPopUp"
      class="holamundo fast-check-popup fast-check-popup1"
      title
      :active.sync="fastCheckPopUp"
    >
      <span id="fast-scroll-top" ref="fastScrollTop"></span>
      <div class="sticky">
        <div class="flex mt-4">
          <h2 class="m-0">Observed Growth through Play</h2>
          <vs-button
            v-if="activeTab === children.length - 1"
            color="primary"
            type="gradient"
            @click="nextPrevious(0)"
            class="ml-auto"
            >Save
          </vs-button>
        </div>
        <div class="overflow-auto">
          <ul class="teacher-list w-full fastcheck-list">
            <li
              v-for="(child, key) in children"
              :class="key === activeTab ? 'active' : ''"
              :key="key"
              style="vertical-align: top"
            >
              <vs-avatar size="large" :src="child.photo" />
              <span>{{ child.name }}</span>
            </li>
          </ul>
        </div>
      </div>

      <vx-card class="mb-base" v-if="children.length">
        <div
          style="margin-top: 30px; margin-bottom: 80px"
          v-for="(check, fastCheckKey) in selectedChildFastCheck"
          :key="fastCheckKey"
        >
          <div class="mb-5">
            <h3 class="h3 font-semibold">
              <!-- {{ fastCheckKey + 1 + "." }} {{ check.name }} -->
              {{ check.name }}
            </h3>
            <p>{{ check.question }}</p>
          </div>
          <div class="range">
            <ul class="range-labels">
              <li>
                <input
                  :id="'one' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="1"
                />
                <label :for="'one' + fastCheckKey">
                  <span></span>
                  1
                </label>
              </li>
              <li>
                <input
                  :id="'two' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="2"
                />
                <label :for="'two' + fastCheckKey">
                  <span></span>
                  2
                </label>
              </li>
              <li>
                <input
                  :id="'three' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="3"
                />
                <label :for="'three' + fastCheckKey">
                  <span></span>
                  3
                </label>
              </li>
              <li>
                <input
                  :id="'four' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="4"
                />
                <label :for="'four' + fastCheckKey">
                  <span></span>
                  4
                </label>
              </li>
              <li>
                <input
                  :id="'five' + fastCheckKey"
                  type="radio"
                  v-model="check.rating"
                  value="5"
                />
                <label :for="'five' + fastCheckKey">
                  <span></span>
                  5
                </label>
              </li>
            </ul>
          </div>
        </div>
        <vs-row>
          <vs-col vs-type="flex" vs-w="12">
            <vs-button
              v-if="activeTab !== 0"
              color="primary"
              type="gradient"
              @click="nextPrevious(-1)"
              class="mr-auto"
              >Previous
            </vs-button>

            <vs-button
              v-if="activeTab !== children.length - 1"
              color="primary"
              type="gradient"
              @click="nextPrevious(1)"
              class="ml-auto"
              >Next
            </vs-button>
            <vs-button
              v-else-if="activeTab === children.length - 1"
              color="primary"
              type="gradient"
              @click="nextPrevious(0)"
              class="ml-auto"
              >Save
            </vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-popup>

    <!--Fast Check Summary Pop Up-->
    <vs-popup
      v-if="completeChildrenFastCheck.length"
      fullscreen
      class="holamundo fast-check-popup"
      title
      :active.sync="fastCheckSummaryPopUp"
      :button-close-hidden="true"
    >
      <div class="flex">
        <h2 class="h1">Observed Growth through Play</h2>
        <span @click="closeSummaryPopUp" class="ml-auto">
          <feather-icon icon="XIcon" class="m-0 cursor-pointer" />
        </span>
      </div>

      <div class="overflow-auto">
        <ul class="fastcheck-list">
          <li
            v-for="(fastCheck, key) in completeChildrenFastCheck"
            :class="key === activeTab ? 'active' : ''"
            @click="updateActiveTabKey(key)"
            :key="key"
          >
            <vs-avatar size="large" :src="fastCheck.childDetail.photo" />
            <span>{{ fastCheck.childDetail.fullName }}</span>
          </li>
        </ul>
      </div>
      <div class="content">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="start"
            vs-align="start"
            vs-lg="8"
            vs-sm="7"
            vs-xs="12"
          >
            <h3 class="h2">Trend Comparison</h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="start"
            vs-align="start"
            vs-lg="4"
            vs-sm="5"
            vs-xs="12"
          >
            <h3 class="h2">Professional development</h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <ul class="rank-title">
              <li><span class="tag average-tag"></span>Individual Average</li>
              <li><span class="tag trend-tag"></span>Room Trend</li>
            </ul>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <template
            v-for="(preset, key) in completeChildrenFastCheck[activeTab]
              .fastchecks"
          >
            <vs-col
              v-bind:key="key + 0.44"
              vs-type="flex"
              vs-justify="start"
              vs-align="start"
              vs-lg="8"
              vs-sm="12"
              vs-xs="12"
              class="rank-col"
            >
              <div class="flex w-full justify-between">
                <span class="font-semibold"
                  >{{ key + 1 }}. {{ preset.presetDetail.name }}</span
                >
                <!--<span>
                  Rank:
                  <strong>{{ preset.roomRank + 1 }}</strong>
                </span>-->
              </div>
              <ul class="rank-bar">
                <!-- <li>0
                  <span
                    v-if="preset.averageValue === 0"
                    class="tag average-tag"
                    :style="
                      preset.averageValue === 0 && preset.trend === 0
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <span
                    v-if="preset.trend === 0 && preset.averageValue !== 0"
                    class="tag trend-tag"
                  ></span>
                </li> -->
                <li>
                  1
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 1"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 1 &&
                      Number(preset.trend.toFixed()) === 1
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <span
                    v-if="
                      Number(preset.trend.toFixed()) === 1 &&
                        Number(preset.averageValue.toFixed()) !== 1
                    "
                    class="tag trend-tag"
                  ></span>
                </li>
                <li>
                  2
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 2"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 2 &&
                      Number(preset.trend.toFixed()) === 2
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <span
                    v-if="
                      Number(preset.trend.toFixed()) === 2 &&
                        Number(preset.averageValue.toFixed()) !== 2
                    "
                    class="tag trend-tag"
                  ></span>
                </li>
                <li>
                  3
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 3"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 3 &&
                      Number(preset.trend.toFixed()) === 3
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <span
                    v-if="
                      Number(preset.trend.toFixed()) === 3 &&
                        Number(preset.averageValue.toFixed()) !== 3
                    "
                    class="tag trend-tag"
                  ></span>
                </li>
                <li>
                  4
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 4"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 4 &&
                      Number(preset.trend.toFixed()) === 4
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <span
                    v-if="
                      Number(preset.trend.toFixed()) === 4 &&
                        Number(preset.averageValue.toFixed()) !== 4
                    "
                    class="tag trend-tag"
                  ></span>
                </li>
                <li style="width: 2%">
                  5
                  <span
                    v-if="Number(preset.averageValue.toFixed()) === 5"
                    class="tag average-tag"
                    :style="
                      Number(preset.averageValue.toFixed()) === 5 &&
                      Number(preset.trend.toFixed()) === 5
                        ? 'border:5px solid #27378E !important; box-sizing:content-box !important; top: -20px !important; left: -10px !important;'
                        : ''
                    "
                  ></span>
                  <span
                    v-if="
                      Number(preset.trend.toFixed()) === 5 &&
                        Number(preset.averageValue.toFixed()) !== 5
                    "
                    class="tag trend-tag"
                  ></span>
                </li>
              </ul>
            </vs-col>
            <vs-col
              v-bind:key="key + 0.55"
              vs-type="flex"
              vs-justify="start"
              vs-align="center"
              vs-lg="4"
              vs-sm="12"
              vs-xs="12"
              class="rank-link-col"
            >
              <a
                href="javascript:;"
                class="font-semibold"
                @click="
                  detailPage(preset.presetDetail._id, preset.averageValue)
                "
              >
                Link to
                {{ preset.presetDetail.name }} Resources
              </a>
            </vs-col>
          </template>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Vue from "vue";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import vSelect from "vue-select";
import VueSimpleSuggest from "vue-simple-suggest";
import LightBox from "vue-image-lightbox";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Collapse from "@/views/pages/components/Collapse";

Vue.use(VueLodash, { name: "custom", lodash: lodash });

const ALLOWED_VIDEO_FILETYPES = ["mp4", "mov", "avi", "webm"];

export default {
  components: { vSelect, VueSimpleSuggest, LightBox, Collapse },
  props: ["selectedActivity", "followUpData"],
  data() {
    return {
      chosen: "",
      showForm: false,
      startTime: new Date(),
      activity: "",
      currentImageIndex: 0,
      purposeStatement: "",
      selectedChildren: [],
      childrenList: "",
      childPopup: false,
      followUpPopUp: false,
      options: [],
      selectedChecklist: [],
      eylfChecklist: "",
      children: [],
      toggle: true,
      isFavourite: false,
      searchText: "",
      photos: [],
      coreSkillId: "",
      name: "",
      description: "",
      waysOfLearningId: "",
      streamId: "",
      loading: false,
      followUpChild: "",
      followUpId: "",
      childKey: null,
      followUpKey: null,
      activeChildIndex: 0,
      activeTab: 0,
      fastCheckPopUp: false,
      childrenFastCheck: [],
      fastCheckList: [],
      fastCheckSummaryPopUp: false,
      completeChildrenFastCheck: [],
      childFastCheckId: null,
      selectedChildFastCheck: [],
      isCompleteFollowUp: 0,
      media: [],
      tagChildrenPopUp: false,
      tagChildrenOptions: [],
      taggedTo: [],
      taggedChildren: [],
      check: "Select the children ",
      roomId: "",
      currentChildrenList: [],
      collapsed: true,
      collapseAll: "",
      eylfCategories: [],
      followUpCompletedText: "",
      selected: "",
      isSpontaneous: false,
      openLightBox: false
    };
  },
  watch: {
    // tagChildrenPopUp(res) {
    //   /*  if( res ) {
    //        this.toggle = false;
    //      } */
    // },

    async selectedActivity(res) {
      let self = this;
      self.children = [];
      self.selectedChildren = [];
      self.activity = res;
      self.init();
    },
    searchText(res) {
      let self = this;
      self.options = self.childrenList.map(item => {
        return {
          label: item.fullName,
          value: item._id,
          photo: item.photo
        };
      });

      let data = self.options;
      let childData = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].label.toLowerCase().includes(res.toLowerCase())) {
          childData.push(data[i]);
        }
      }
      self.options = childData;
    }
  },
  methods: {
    simpleSuggestionList() {
      return ["Vue.js", "React.js", "Angular.js"];
    },
    ...mapActions("activity", [
      "addChildren",
      "updateActivity",
      "changeFavouriteStatus",
      "uploadImageToActivity",
      "removeImageFromActivityById",
      "fetchActivityByLikeName",
      "storeActivity",
      "deleteActivityById",
      "editFollowUpActivity",
      "editFollowUpStatus",
      "deleteFollowUp",
      "fetchActivityPhotosByActivityId"
    ]),
    ...mapActions("fastCheck", ["fetchFastChecks", "fetchRandomFastChecks"]),
    ...mapActions("eylfCategory", ["fetchEYLFCategoryWithFrameworkByCenterId"]),
    ...mapActions("teacher", [
      "saveChildFastCheck",
      "fetchChildFastCheckByChildIdAndChildFastCheckId",
      "fetchChildFastCheckByChildFastCheckId"
    ]),
    ...mapActions("child", ["fetchChildrenByRoomId", "storeTagChildren"]),
    expandCollapseAll() {
      this.collapsed = !this.collapsed;
    },
    closeSummaryPopUp() {
      let self = this;
      self.fastCheckSummaryPopUp = false;
      setTimeout(function() {
        self.$parent.getActivities();
        self.$parent.activityEditFormDisplay = false;
      }, 200);
    },
    editActivity(activity) {
      this.$emit("editActivity", activity);
    },
    showChildPopUp() {
      this.currentChildrenList = this.selectedChildren;
      this.childPopup = true;
    },
    detailPage(fastCheckPresetId, rating) {
      let routeData = this.$router.resolve({
        name: "fast-check-document",
        params: { fastCheckPresetId: fastCheckPresetId, rating: rating }
      });
      window.open(routeData.href, "_blank");
    },
    followUpComplete(followUpId, followUpIndex) {
      if (this.followUpCompletedText) {
        let obj = new FormData();
        obj.append("isCompleted", true);
        obj.append("followUpCompletedText", this.followUpCompletedText);
        let data = {
          activityId: followUpId,
          obj: obj
        };

        this.editFollowUpStatus(data)
          .then(async c => {
            this.$parent.getActivities();
            this.children[
              this.activeChildIndex
            ].followUps[0].followUpCompletedText = this.followUpCompletedText;
            this.children[
              this.activeChildIndex
            ].followUps[0].isCompleted = true;
            this.success("Follow up has been completed.");
          })
          .catch(err => {
            this.error(err.message);
          });
      } else {
        this.error("Please enter compete text.");
      }
    },
    async openGallery(image, index) {
      let self = this;
      this.openLightBox = true;
      await this.$refs.lightbox;
      this.$refs.lightbox.showImage(index);
      this.currentImageIndex = index;
      this.getTaggedList(index);
      setTimeout(function() {
        document.getElementById("tagPhoto").onclick = function() {
          self.tagPhoto();
        };
      }, 1000);
    },
    onImageChanged(index) {
      this.currentImageIndex = index;
      this.getTaggedList(index);
    },
    tagPhoto() {
      this.$refs["checkboxAllTag"].checked = false;
      this.$refs["checkboxAllChildren"].checked = false;
      this.tagChildrenPopUp = true;
    },

    selectChildToUpdate(child, key) {
      this.activeChildIndex = key;
      if (
        this.children[key].followUps &&
        this.children[key].followUps.length > 0
      ) {
        this.followUpCompletedText = this.children[
          key
        ].followUps[0].followUpCompletedText;
      } else {
        this.followUpCompletedText = "";
      }
    },

    /* *********** check video ext *************** */
    hasAllowedVideoExtension(url) {
      const extension = url.split(".").pop();
      return ALLOWED_VIDEO_FILETYPES.includes(extension);
    },

    convertVideoFileExtenion(url) {
      const thumbArray = url.split("/");
      const thumbUrl = thumbArray[4];
      let thumbIndex = null;
      if (thumbUrl.length) {
        const thumbIndexArray = thumbUrl.split(".");
        thumbIndex = thumbIndexArray[0];
      }
      return `${process.env.VUE_APP_IMAGE_URL}undefined/video-thumb/${thumbIndex}.png`;
    },

    /* frontend video thumbnail generator for video  */
   
    async getImageThumbnail(url, maxRetries = 1, delay = 4000) {
      let attempt = 0;
      const thumbnailUrl = this.convertVideoFileExtenion(url);
      async function checkUrl() {
        try {
          const response = await fetch(thumbnailUrl, { method: "HEAD" });
          if (response.ok) {
            return true;
          } else {
            throw new Error("URL not found");
          }
        } catch (error) {
          if (attempt < maxRetries) {
            attempt++;
            // console.log(
            //   `Attempt ${attempt} failed. Retrying in ${delay /
            //     1000} seconds...`
            // );
            await new Promise(resolve => setTimeout(resolve, delay));
            return checkUrl();
          } else {
            return false;
          }
        }
      }

      return checkUrl();
    },

    async uploadImage(file) {
      this.$vs.loading();
      let imageFile = file;
      if (file.length > 0) {
        let obj = new FormData();

        for (let i = 0; i < imageFile.length; i++) {
          obj.append("file", imageFile[i]);
        }

        let data = {
          activityId: this.activity._id,
          obj: obj,
          config: {
            header: {
              "Content-Type": "image/png"
            }
          }
        };

        this.uploadImageToActivity(data)
          .then(async res => {
            this.photos = res.data.data;
            this.media = [];
            const promises = [];
            this.photos.map(async item => {
              if (this.hasAllowedVideoExtension(item.photo)) {
                promises.push(
                  new Promise(async (resolve, reject) => {
                    let thumbnail;
                    await this.getImageThumbnail(item.photo).then(exists => {
                      thumbnail = this.convertVideoFileExtenion(item.photo);
                    });

                    resolve({
                      // For video
                      id: item._id,
                      type: "video",
                      thumb: thumbnail,
                      src: item.photo,
                      sources: [
                        {
                          src: item.photo,
                          type: "video/mp4"
                        }
                      ],
                      caption:
                        '<a href="javascript:void(0)" class="tag-photo" id="tagPhoto">Tag Photo and Video</a>',
                      autoplay: false
                    });
                  })
                );

              } else {
                promises.push(
                  new Promise((resolve, reject) => {
                    resolve({
                      id: item._id,
                      thumb: item.photo,
                      src: item.photo,
                      caption:
                        '<a href="javascript:void(0)" class="tag-photo" id="tagPhoto">Tag Photo and Video</a>',
                      srcset: item.photo
                    });
                  })
                );
              }
            });

            this.media = await Promise.all(promises);

            let $this = this;
            setTimeout(function() {
              document.getElementById("tagPhoto").onclick = function() {
                $this.tagPhoto();
              };
            }, 1000);

            this.$vs.loading.close();
          })
          .catch(err => {
            //console.log(err);
            this.$vs.loading.close();
          });
      }
      this.$vs.loading.close();
    },
    removeImage(imageId) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          self.$vs.loading();
          const index = lodash.findIndex(self.media, function(o) {
            return o.id === imageId;
          });
          let data = {
            activityId: self.activity._id,
            imageId: imageId
          };

          self
            .removeImageFromActivityById(data)
            .then(async result => {
              self.media.splice(index, 1);
              self.$vs.loading.close();
            })
            .catch(err => {
              self.$vs.loading.close();
            });
        }
      });
    },
    selectAllChildren(res) {
      this.toggle = !res;
      if (res) {
        this.selectedChildren = this.options;
      } else {
        this.selectedChildren = [];
      }
    },
    selectAllTagChildren(res) {
      this.toggle = !res;
      if (res) {
        this.taggedTo = this.tagChildrenOptions;
      } else {
        this.taggedTo = [];
      }
    },
    submitActivity() {
      let childHasChangesFilled = true;
      let childCoreReflectionFilled = true;
      let childObservedGrowthFilled = true;

      if (this.hasInCompletedFollowupCompleted) {
        this.$vs.notify({
          text: "Please Complete all child follow up activity first.",
          title: "Failed",
          color: "danger"
        });
        return false;
      }

      if (this.hasEmptyChildren) {
        this.$vs.notify({
          text: "Please add atleast one child for this activity.",
          title: "Failed",
          color: "danger"
        });
        return false;
      }

      const endTime = new Date();
      const timeTaken = (endTime.getTime() - this.startTime.getTime()) / 1000;
      let obj = new FormData();
      let childrenData = this.children.map(item => {
        item.howHasChanged ? "" : (childHasChangesFilled = false);
        item.coreSkillReflection ? "" : (childCoreReflectionFilled = false);
        item.growthInSafety ? "" : (childObservedGrowthFilled = false);

        return {
          childId: item.childId,
          childVoice: item.childVoice,
          coreSkillReflection: item.coreSkillReflection,
          growthInSafety: item.growthInSafety,
          howHasChanged: item.howHasChanged
        };
      });

      // if (!childHasChangesFilled) {
      //   this.$vs.notify({
      //     text: "Please fill how has child's changed for all children.",
      //     title: "Failed",
      //     color: "danger"
      //   });
      //   return false;
      // }
      if (!childCoreReflectionFilled) {
        this.$vs.notify({
          text:
            "Please fill how has child's core skill reflection changed for all children.",
          title: "Failed",
          color: "danger"
        });
        return false;
      }

      if (!childObservedGrowthFilled) {
        this.$vs.notify({
          text: "Please fill all child's observed growth.",
          title: "Failed",
          color: "danger"
        });
        return false;
      }
      obj.append(
        "purposeStatement",
        this.purposeStatement ? this.purposeStatement : ""
      );
      obj.append("eylfFrameworks", this.selectedChecklist);
      obj.append("children", JSON.stringify(childrenData));
      obj.append("timeTaken", timeTaken);
      obj.append("isSpontaneous", this.isSpontaneous);
      obj.append("status", 'complete');

      let data = {
        activityId: this.activity._id,
        obj: obj,
        config: {
          header: {
            "Content-Type": "image/"
          }
        }
      };
      this.updateActivity(data)
        .then(async c => {
          this.activeTab = 0;

          if (this.children.length > 0) {
            let a = await this.fastCheckList.map(item => {
              return {
                FastCheckPresetId: item._id,
                name: item.name,
                description: item.description,
                rating: null,
                childId: this.children[this.activeTab].childId,
                fastCheckQuestionId: item.questions ? item.questions._id : null,
                question: item.questions ? item.questions.name : null
              };
            });
            this.selectedChildFastCheck = a;
          }
          this.$router.push({ name: "Documentation" });
          this.success("Data saved.");
          // this.fastCheckPopUp = true;
          this.closeSummaryPopUp()
        })
        .catch(err => {
          this.error(err.message);
        });
    },
    submitDraft() {
      let self = this;
      const endTime = new Date();
      const timeTaken = (endTime.getTime() - this.startTime.getTime()) / 1000;
      let obj = new FormData();
      let childrenData = this.children.map(item => {
        return {
          childId: item.childId,
          childVoice: item.childVoice,
          coreSkillReflection: item.coreSkillReflection,
          growthInSafety: item.growthInSafety,
          howHasChanged: item.howHasChanged
        };
      });
      obj.append(
        "purposeStatement",
        this.purposeStatement ? this.purposeStatement : ""
      );
      obj.append("eylfFrameworks", this.selectedChecklist);
      obj.append("children", JSON.stringify(childrenData));
      obj.append("timeTaken", timeTaken);
      obj.append("isSpontaneous", false);
      let data = {
        activityId: this.activity._id,
        obj: obj,
        config: {
          header: {
            "Content-Type": "image/png"
          }
        }
      };

      this.updateActivity(data)
        .then(res => {
          this.$vs.notify({
            text: "Edit Complete",
            title: "Success",
            color: "success"
          });
          setTimeout(function() {
            self.$router.go();
          }, 1000);
        })
        .catch(err => {
          this.error(err.message);
          this.$vs.notify({
            text: "Failed to save draft",
            title: "Failed",
            color: "danger"
          });
        });
    },
    makeSpontaneous() {
      let self = this;
      const endTime = new Date();
      const timeTaken = (endTime.getTime() - this.startTime.getTime()) / 1000;
      let obj = new FormData();
      let childrenData = this.children.map(item => {
        return {
          childId: item.childId,
          childVoice: item.childVoice,
          coreSkillReflection: item.coreSkillReflection,
          growthInSafety: item.growthInSafety,
          howHasChanged: item.howHasChanged
        };
      });
      obj.append(
        "purposeStatement",
        this.purposeStatement ? this.purposeStatement : ""
      );
      obj.append("eylfFrameworks", this.selectedChecklist);
      obj.append("children", JSON.stringify(childrenData));
      obj.append("timeTaken", timeTaken);
      obj.append("isSpontaneous", true);
      let data = {
        activityId: this.activity._id,
        obj: obj,
        config: {
          header: {
            "Content-Type": "image/png"
          }
        }
      };

      this.updateActivity(data)
        .then(res => {
          this.$vs.notify({
            text: "Made spontaneous successfully",
            title: "Success",
            color: "success"
          });
          setTimeout(function() {
            self.$router.go();
          }, 1000);
        })
        .catch(err => {
          this.error(err.message);
          this.$vs.notify({
            text: "Failed to save draft",
            title: "Failed",
            color: "danger"
          });
        });
    },
    async getChildrenList(roomId) {
      await this.fetchChildrenByRoomId(roomId).then(async children => {
        this.childrenList = children.data.data;
        this.tagChildrenOptions = this.childrenList.map(item => {
          return {
            text: item.fullName,
            value: item._id,
            label: item.fullName,
            photo: item.photo
          };
        });
      });
    },
    async getTaggedList(val) {
      let self = this;
      let currentPhotoTag = self.photos[val].taggedTo;
      let taggedChildren = [];
      if (currentPhotoTag.length) {
        for (let i = 0; i < currentPhotoTag.length; i++) {
          let a = await this.lodash.filter(this.childrenList, {
            _id: currentPhotoTag[i]
          });
          a = a.map(item => {
            return {
              text: item.fullName,
              value: item._id,
              label: item.fullName,
              photo: item.photo
            };
          });
          taggedChildren.push(a[0]);
        }
      }
      self.taggedTo = taggedChildren;
    },
    async cancelTagChildren() {
      let self = this;
      self.tagChildrenPopUp = false;
    },
    async confirmTag() {
      let childrenTagged = this.taggedTo.map(item => {
        let existData = lodash.filter(this.children, { childId: item.value });
        return {
          label: item.fullName,
          photo: item.photo,
          value: item.value
        };
      });
      let b = this.taggedTo.map(item => {
        return item.value;
      });
      const info = {
        tagged: b,
        photo: this.media[this.currentImageIndex],
        activityId: this.activity._id
      };
      await this.storeTagChildren(info).then(res => {
        this.tagChildrenPopUp = false;
      });
      this.taggedChildren = [];
      setTimeout(function() {
        this.taggedChildren = childrenTagged;
      }, 300);

      await this.fetchActivityPhotosByActivityId(this.activity._id).then(
        res => {
          this.photos = res.data.data;
        }
      );
    },
    async getEylfChecklist(centerId) {
      await this.fetchEYLFCategoryWithFrameworkByCenterId(centerId).then(
        checklist => {
          this.eylfCategories = checklist.data.data.eylfCategories;
        }
      );
    },
    async cancelAddChildren() {
      let self = this;
      if (self.activity.children[0].child.length) {
        self.selectedChildren = [];
        await self.activity.children.map(item => {
          self.selectedChildren.push({
            label: item.child[0].fullName,
            photo: item.child[0].photo,
            value: item.childId
          });
        });
      }
      self.childPopup = false;
    },
    async addChildrenToActivity() {
      let self = this;
      let currentChildren = self.children.length;
      let x = lodash.difference(
        self.currentChildrenList,
        self.selectedChildren
      );
      if (self.selectedChildren.length > 20) {
        self.error("You cannot add more than 20 children to activity.");
      } else {
        self.$vs.loading();
        let childrenPush = self.selectedChildren.map(item => {
          let existData = lodash.filter(self.children, { childId: item.value });
          return {
            childId: item.value,
            howHasChanged:
              existData.length > 0 ? existData[0].howHasChanged : "",
            coreSkillReflection:
              existData.length > 0 ? existData[0].coreSkillReflection : "",
            childVoice: existData.length > 0 ? existData[0].childVoice : "",
            growthInSafety:
              existData.length > 0 ? existData[0].growthInSafety : "",
            photo: item.photo,
            name: item.label,
            followUps: existData.length > 0 ? existData[0].followUps : []
          };
        });

        let addData = childrenPush.map(item => {
          return {
            childId: item.childId,
            howHasChanged: item.howHasChanged,
            coreSkillReflection: item.coreSkillReflection,
            childVoice: item.childVoice,
            growthInSafety: item.growthInSafety
          };
        });

        let obj = {
          children: addData,
          activityId: self.activity._id
        };

        self
          .addChildren(obj)
          .then(async result => {
            await self.fetchChildrenByRoomId(self.teacher.roomId);

            if (x.length > 0) {
              let removeChild = x.map(item => {
                return item.value;
              });
              let y = {
                parentActivityId: self.activity._id,
                child: removeChild
              };
              await self.deleteFollowUp(y);
            }
            if (currentChildren > 0) {
              this.success("Data Updated");
            } else {
              this.success("Data Added");
            }
            self.$parent.getActivities();
            self.children = [];
            self.$vs.loading.close();

            setTimeout(function() {
              self.children = childrenPush;
            }, 300);
          })
          .catch(err => {
            this.error(err.message);
            self.$vs.loading.close();
          });
        self.childPopup = false;
        self.$vs.loading.close();

        this.$vs.loading.close();
      }
    },
    /*Favourite status update*/
    async updateFavouriteStatus() {
      this.$vs.loading();
      this.changeFavouriteStatus(this.activity._id)
        .then(async result => {
          this.isFavourite = await result.data.data.isFavourite;
          this.success(
            this.isFavourite === true
              ? "Activity has been added to Favorite List."
              : "Activity has been removed from Favorite List"
          );
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          this.error(err.message);
        });
    },
    async init() {
      let self = this;
      self.isFavourite = self.activity.isFavourite;
      self.photos = self.activity.photos;
      self.roomId = self.activity.roomId;
      await self.getChildrenList(self.selectedActivity.roomId);
      self.getEylfChecklist(self.teacher.learningCenterId);
      self.purposeStatement = self.activity.purposeStatement;
      self.selectedChecklist = self.activity.eylfFrameworks;
      self.completeChildrenFastCheck = [];
      self.options = self.childrenList.map(item => {
        return {
          label: item.fullName,
          value: item._id,
          photo: item.photo
        };
      });
      self.media = self.photos.map(item => {
        if (this.hasAllowedVideoExtension(item.photo)) {
          const thumbnail = this.convertVideoFileExtenion(item.photo);
          return {
            // For video
            id: item._id,
            type: "video",
            thumb: thumbnail,
            src: item.photo,
            sources: [
              {
                src: item.photo,
                type: "video/mp4"
              }
            ],
            caption:
              '<a href="javascript:void(0)" class="tag-photo" id="tagPhoto">Tag Photo and Video</a>',
            autoplay: false
          };
        } else {
          return {
            id: item._id,
            thumb: item.photo,
            src: item.photo,
            caption:
              '<a href="javascript:void(0)" class="tag-photo" id="tagPhoto">Tag Photo and Video</a>',
            srcset: item.photo
          };
        }
      });
      if (self.activity.children[0].child.length) {
        self.children = await self.activity.children.map(item => {
          return {
            childId: item.childId,
            howHasChanged: item.howHasChanged,
            coreSkillReflection: item.coreSkillReflection,
            childVoice: item.childVoice,
            growthInSafety: item.growthInSafety,
            photo: item.child[0].photo,
            name: item.child[0].fullName,
            followUps: item.followUp
          };
        });

        self.activity.children.map(item => {
          self.selectedChildren.push({
            label: item.child[0].fullName,
            photo: item.child[0].photo,
            value: item.childId
          });
        });
        if (
          self.children.length > 0 &&
          self.children[0].followUps &&
          self.children[0].followUps.length > 0
        ) {
          self.followUpCompletedText =
            self.children[0].followUps[0].followUpCompletedText;
        }
      }
      if (self.followUpData) {
        self.selectFollowUpChild(self.followUpData);
      }
    },
    addChildFollowUp(res, key) {
      let self = this;
      self.followUpChild = res;
      self.followUpPopUp = true;
    },
    /*suggestion title*/
    getList(inputValue) {
      return new Promise(async (resolve, reject) => {
        let data = {
          learningCenterId: this.teacher.learningCenterId,
          inputValue: inputValue
        };
        await this.fetchActivityByLikeName(data)
          .then(response => {
            const jsondata = JSON.stringify(response.data.data);
            const parsed = JSON.parse(jsondata);
            const result = [];
            parsed.forEach(searchResult => {
              result.push({
                id: searchResult._id,
                title: searchResult.name,
                description: searchResult.description,
                link: ""
              });
            });

            resolve(result);
          })
          .catch(error => {
            this.loading = false;
            reject(error);
          });
      });
    },
    onSuggestSelect(suggest) {
      this.selected = suggest.title;
      this.description = suggest.description;
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const { suggestion, query } = scope;
      let result = this.$refs.suggestComponent.displayProperty(
        suggestion.title
      );
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [""];
      return result.replace(
        new RegExp("(.*?)(" + texts.join("|") + ")(.*?)", "gi"),
        "$1<b>$2</b>$3"
      );
    },
    /*Add Follow up*/
    submitFollowUp() {
      this.$vs.loading();
      this.$validator.validateAll().then(async result => {
        if (result) {
          let obj = new FormData();

          if (this.followUpId === "") {
            obj.append("childId", this.followUpChild.childId);
            obj.append("parentActivityId", this.activity._id);
            obj.append("learningCenterId", this.teacher.learningCenterId);
            obj.append("roomId", this.teacher.roomId);
            obj.append("teacherId", this.teacher._id);
          }

          obj.append("name", this.name);
          obj.append("description", this.description);
          obj.append("coreSkills", this.coreSkillId);
          obj.append("waysOfLearnings", this.waysOfLearningId);
          obj.append("stream", this.streamId);

          let data = {
            obj: obj,
            config: {
              header: {
                "Content-Type": "image/png"
              }
            }
          };

          if (this.followUpId) {
            data.activityId = this.followUpId;
            await this.editFollowUpActivity(data)
              .then(async c => {
                this.$vs.loading.close();
                this.children[this.activeChildIndex].followUps[
                  this.followUpKey
                ] = await c.data.data;
                this.followUpPopUp = false;
                this.initValues();
                this.followUpId = "";
                this.$parent.getActivities();
                this.success("Follow Up has been updated.");
                this.errors.clear();
                this.$nextTick(() => {
                  this.$validator.reset();
                });
              })
              .catch(err => {
                this.$vs.loading.close();
                this.followUpChild = false;
                this.error(err.message);
              });
          } else {
            await this.storeActivity(data)
              .then(async c => {
                this.children[this.activeChildIndex].followUps.push({
                  ...c.data.data,
                  isCompleted: false
                });
                this.$vs.loading.close();
                this.followUpPopUp = false;
                this.initValues();
                this.$parent.getActivities();
                this.followUpCompletedText = "";

                this.success("Follow Up has been added.");
                await this.fetchChildrenByRoomId(this.teacher.roomId);

                this.errors.clear();
                this.$nextTick(() => {
                  this.$validator.reset();
                });
              })
              .catch(err => {
                this.followUpId = "";
                this.$vs.loading.close();
                this.followUpChild = false;
                this.error(err.message);
              });
          }
        } else {
          this.$vs.loading.close();
        }
      });
    },
    /*form value empty*/
    initValues() {
      this.coreSkillId = "";
      this.waysOfLearningId = "";
      this.name = "";
      this.description = "";
      this.streamId = "";
      this.followUpId = "";
    },
    /*Follow up remove*/
    async removeFollowUp(activityId, fKey) {
      this.$vs.loading();
      await this.deleteActivityById(activityId)
        .then(async result => {
          this.$delete(this.children[this.activeChildIndex].followUps, fKey);

          this.$vs.loading.close();
          this.success("Follow up has been removed.");
          this.followUpId = "";
        })
        .catch(err => {
          this.followUpId = "";
          this.$vs.loading.close();
          this.error("Follow up could not be removed.");
        });
    },
    /*edit follow form value display*/
    editFollowUp(activity, fKey) {
      this.coreSkillId = activity.coreSkills[0]._id;
      this.waysOfLearningId = activity.waysOfLearnings[0]._id;
      this.name = activity.name;
      this.description = activity.name;
      this.streamId = activity.streams[0]._id;
      this.followUpId = activity._id;
      this.followUpKey = fKey;
      this.followUpPopUp = true;
    },
    /*Get Fast Check List*/
    async getFastCheck() {
      await this.fetchRandomFastChecks(this.teacher.room.ageGroup).then(
        async result => {
          this.fastCheckList = result.data.data;
        }
      );
    },
    async nextPrevious(value) {
      if (value != "-1") {
        const fastCheckWithNullValue = this.selectedChildFastCheck.filter(
          (item, index, arr) => {
            return !item.rating;
          }
        );

        if (fastCheckWithNullValue.length > 0) {
          this.$vs.notify({
            text: "Please check value for all fast checks.",
            title: "Failed",
            color: "danger"
          });
          return;
        }
      }

      this.$vs.loading();

      let fastCheckData = this.selectedChildFastCheck.map(item => {
        return {
          roomId: this.teacher.roomId,
          childId: item.childId,
          FastCheckPresetId: item.FastCheckPresetId,
          teacherId: this.teacher._id,
          activityId: this.selectedActivity._id,
          rating: item.rating,
          fastCheckQuestionId: item.fastCheckQuestionId,
          lastIndex: this.activeTab === this.children.length - 1 && value !== -1
        };
      });
      await this.saveChildFastCheck(fastCheckData)
        .then(async response => {
          this.childFastCheckId = response.data.data.childFastCheckId;

          if (this.activeTab === this.children.length - 1 && value !== -1) {
            await this.fetchChildFastCheckByChildFastCheckId(
              this.childFastCheckId
            )
              .then(result => {
                const arrayMap = result.data.data.reduce(
                  (accumulator, currentValue) => ({
                    ...accumulator,
                    [currentValue.childDetail._id]: currentValue
                  }),
                  {}
                );
                const sortedresult = this.children.map(
                  key => arrayMap[key.childId]
                );

                this.activeTab = 0;
                this.completeChildrenFastCheck = sortedresult;

                this.$vs.loading.close();
              })
              .catch(err => {
                /* console.log(err) */
              });

            this.$vs.loading.close();
            this.fastCheckSummaryPopUp = true;
            this.fastCheckPopUp = false;
          } else {
            this.activeTab = this.activeTab + value;
            let obj = {
              childId: this.children[this.activeTab].childId,
              childFastCheckId: this.childFastCheckId
            };

            await this.fetchChildFastCheckByChildIdAndChildFastCheckId(
              obj
            ).then(response => {
              let data = response.data.data;

              if (data.length > 0) {
                this.selectedChildFastCheck = data.map(item => {
                  return {
                    name: item.preset.length > 0 ? item.preset[0].name : "",
                    description:
                      item.preset.length > 0 ? item.preset[0].description : "",
                    rating: item.rating,
                    childId: item.childId,
                    FastCheckPresetId: item.FastCheckPresetId,
                    fastCheckQuestionId: item.fastCheckQuestionId,
                    question: item.question.name
                  };
                });
              } else {
                this.selectedChildFastCheck = this.selectedChildFastCheck.map(
                  item => {
                    return {
                      name: item.name,
                      description: item.description,
                      rating: null,
                      childId: this.children[this.activeTab].childId,
                      FastCheckPresetId: item.FastCheckPresetId,
                      fastCheckQuestionId: item.fastCheckQuestionId,
                      question: item.question
                    };
                  }
                );
              }
              this.$vs.loading.close();
              document
                .getElementById("fast-scroll-top")
                .scrollIntoView({ block: "start" });
            });
            this.$vs.loading.close();
          }
        })
        .catch(err => {
          // console.log(err);
        });
    },
    /*Success notify*/
    success(message = "Data Saved") {
      this.$vs.notify({
        title: "Success",
        text: message,
        color: "success",
        iconPack: "feather",
        position: "top-right",
        icon: "icon-check-circle"
      });
    },
    /*error notify*/
    error(message = "Task Failed") {
      this.$vs.notify({
        title: "Error",
        text: message,
        color: "danger",
        iconPack: "feather",
        position: "top-right",
        icon: "icon-times"
      });
    },
    updateActiveTabKey(value) {
      this.activeTab = value;
    },
    selectFollowUpChild(activity) {
      this.activeChildIndex = lodash.findIndex(this.children, function(o) {
        return (
          o.childId ==
          (Array.isArray(activity.children)
            ? activity.children[0].childId
            : activity.children.childId)
        );
      });
    }
  },
  async mounted() {
    let $this = this;

    setTimeout(function() {
      if (document.getElementById("tagPhoto")) {
        document.getElementById("tagPhoto").onclick = function() {
          $this.tagPhoto();
        };
      }
    }, 1000);

    let self = this;
    self.selectedChildren = [];
    self.activity = self.selectedActivity;
    self.init();
  },
  created() {
    this.getFastCheck();
    this.getChildrenList(this.selectedActivity.roomId);
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    },
    coreSkills() {
      return this.$store.state.coreSkill.coreSkills;
    },
    streams() {
      return this.$store.state.stream.streams;
    },
    waysOfLearningList() {
      return this.$store.state.waysOfLearning.waysOfLearning;
    },
    hasInCompletedFollowupCompleted() {
      let hasIncompleted = false;

      this.children.forEach(function(child) {
        if (child.followUps.length > 0) {
          if (!child.followUps[0].isCompleted) {
            hasIncompleted = true;
          }
        } else {
          hasIncompleted = true;
        }
      });

      return hasIncompleted;
    },
    hasEmptyChildren() {
      let hasIncompleted = false;

      if (this.children.length == 0) {
        hasIncompleted = true;
      }

      return hasIncompleted;
    }
  }
};
</script>
<style lang="scss">
.vs-popup {
  min-height: 300px;
}

video {
  max-width: 100%;
  height: 700px;
  &.video-slider {
    height: 100px;
  }
}

#tag-children .vx-card__body {
  max-height: 50vh;
  overflow: auto;
}

.eylf-card {
  .eylf-col {
    border-bottom: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    padding-right: 30px;
    padding-left: 20px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
    }

    @media (max-width: 900px) {
      border-right: none;
      margin-right: 0;
    }
    @media (max-width: 1200px) {
      .mr-8 {
        margin-right: 10px !important;

        .con-vs-checkbox {
          margin-top: 5px;
        }
      }
    }
    @media (max-width: 900px) {
      border-right: none;
      .mr-8 {
        margin-right: 10px !important;

        .con-vs-checkbox {
          margin-top: 5px;
        }
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #5e5e5e;
  }
}

.con-img-upload {
  padding-bottom: 50px;

  .img-upload {
    margin: 0 15px 15px 0;
  }
}

.con-input-upload {
  background: none;
  border: 2px solid #2d9ad5;
  border-radius: 5px;
  width: auto;
  height: auto;
  margin: 0;
  padding: 10px 20px;
  min-height: 42px;
  min-width: 150px;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 5px;
}

.con-input-upload {
  .vs-upload--button-upload {
    display: none;
  }
}

.con-input-upload {
  .text-input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 19px;
    color: #2d9ad5;
    font-weight: 600;
    z-index: -1;
    min-width: auto;
  }
}

.add-children-popup,
.tag-children-popup {
  .vs-popup {
    @media (min-width: 992px) {
      width: 825px;
    }
  }

  .vs-popup--header {
    height: 0;
  }

  .vs-popup--close {
    transform: none !important;
    background: none !important;
    box-shadow: none;
    position: relative;
    top: 35px;
    right: 20px;
    font-size: 25px;
    color: #2b2d3d;

    &:hover {
      color: #2d9ad5;
    }
  }

  .vs-popup--content {
    padding: 45px 35px !important;
    margin: 0;
    width: 100%;

    .vx-card {
      box-shadow: none;

      .vx-card__body {
        // padding: 10px !important;
        border-radius: 8px !important;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);

        .vs-con-input-label.is-label-placeholder {
          margin-top: 0 !important;
          margin-bottom: 25px;
        }
      }
    }
  }

  .vs-checkbox-primary {
    .vs-checkbox {
      width: 20px !important;
      height: 20px !important;
      border: 1px solid #289ad5 !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      overflow: hidden;
      transform: none;
    }
  }

  .round {
    label {
      cursor: pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #289ad5 !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      transform: none;
      margin: 0 5px;
      background: transparent;
      transition: 0.4s all ease-in-out;

      i {
        opacity: 0;
      }
    }
  }
}

.add-children-popup {
  .vs-checkbox-primary {
    .vs-checkbox {
      margin-right: 5px;
      border: none !important;

      .vs-checkbox--check {
        border: 1px solid #2d9ad5 !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: none;
        transition: 0.4s all ease-in-out;
      }

      .vs-icon {
        opacity: 0;
      }
    }
  }
}

.add-children-popup
  .vs-checkbox--input:checked
  + .vs-checkbox
  .vs-checkbox--check {
  transform: none;
  top: 0;
  left: 0;
  border: 10px solid #2d9ad5 !important;
  width: 100%;
  height: 100%;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}

.add-children-popup .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  transform: none;
  opacity: 1;
}

.add-children-popup ul li {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.add-children-popup .vs-checkbox-primary .con-slot-label {
  margin-left: 6px;
  font-size: 16px;
  line-height: 22px;
}

.round {
  position: relative;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  border: 10px solid #2898d5 !important;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}

.round input[type="checkbox"]:checked + label i {
  opacity: 1;
  position: relative;
  top: 1px;
  left: -10px;
  font-size: 20px;
  color: #ffffff;
  transform: none;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #212121;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}

@mixin rangeThumb {
  width: 18px;
  height: 18px;
  margin: -8px 0 0;
  border-radius: 50%;
  background: #37adbf;
  cursor: pointer;
  border: 0 !important;
  position: relative;
  z-index: 1;
}

@mixin rangeTrack {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: grey;
}

.range {
  position: relative;
  width: 900px;
  height: 5px;
}

.range input[type="radio"] {
  border: 13.5px solid #2898d5;
  border-radius: 50%;
  width: 27px;
  position: absolute;
  height: 27px;
  -webkit-appearance: none;
  top: -12px;
  right: -12px;
  transition: 0.4s all ease-in-out;
  z-index: 1;
  opacity: 0;

  &:checked {
    opacity: 1;
  }
}

// Labels below slider
.range-labels {
  background: #f0f0f0;
  height: 6px;
  border-radius: 3px;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    position: relative;
    width: calc(100% / 4);
    text-align: left;
    color: black;
    font-size: 14px;
    height: 6px;
    top: -9px;
    cursor: pointer;

    &:first-child {
      width: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: -4px;
      right: 0;
      width: 3px;
      height: 13px;
      background: #606060;
    }

    label {
      position: absolute;
      top: 20px;
      right: -2px;
    }
  }

  .active {
    color: #37adbf;
  }

  .selected::before {
    background: #37adbf;
  }

  .selected::after {
    background: #37adbf;
  }

  .active.selected::before {
    display: none;
  }
}

.learning-activity-table {
  tr {
    th {
      padding: 10px 30px;

      &:nth-child(1) {
        width: 305px;
      }

      &:nth-child(2) {
        width: 780px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 180px;
      }

      &:nth-child(5) {
        width: 250px;
      }

      @media (max-width: 480px) {
        padding: 10px 15px;
      }
    }

    td {
      padding: 10px 30px;
      @media (max-width: 480px) {
        // padding: 15px;
      }
    }
  }
}

.con-vs-slider {
  z-index: 1;
}

.vs-slider--circle {
  background: #289ad5;
}

.range-labels li::before {
  z-index: 1;
}

.textarea1 {
  @media (max-width: 600px) {
    .vs-xs-12 {
      margin-bottom: 0 !important;
    }
  }
}

.textarea2 {
  @media (max-width: 600px) {
    .vs-xs-12 {
      padding: 0 !important;
    }
  }
}

.photo-wrapper {
  .vs-button {
    display: inline-block;
    margin-top: 20px;
  }
}
.video-wrapper {
  .vs-button {
    display: inline-block;
    margin-top: 20px;
  }
}
.photo-item {
  margin-right: 10px;
  text-align: center;
  position: relative;
}
.playButton {
  object-fit: contain !important;
  margin: 0;
  text-align: center;
  position: absolute;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.vue-lb-figure {
  padding-bottom: 20px;
  line-height: 0;
}

.vue-lb-footer {
  padding: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.vue-lb-info {
  background: none;
  position: absolute;
  left: 0;
  bottom: 8px;
  text-align: left;
  height: auto;
  z-index: 1;

  a {
    font-weight: 700;
    text-decoration: underline;
    color: #50c1ff;
  }
}

.follow-activity-table {
  .text-large {
    font-size: 20px;
    margin-right: 10px;
  }

  tr {
    td {
      vertical-align: top;
      padding: 10px 30px;

      &:first-child {
        padding: 10px 30px;
      }
    }
  }
}

.tag-children-popup {
  .vs-popup--title {
    visibility: hidden;
  }

  .vs-popup {
    min-height: 500px;

    p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 5px;
    }

    .con-select {
      .vs-select--input {
        padding: 20px;
        border: 1px solid #838383;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.vs-select-primary .vs-select--item {
  font-size: 16px;
  padding: 10px;

  span {
    font-size: 16px;
    line-height: 25px;
  }

  .vs-select--item-icon {
    font-size: 30px;
  }

  &.activex {
    padding-left: 40px;
  }
}

.vs-collapse {
  .vs-component {
    align-items: flex-start;
  }
}

.vs-collapse-item {
  border: none;
  margin-bottom: 30px;
  padding-right: 30px;

  .vs-collapse-item--header {
    padding: 0;

    .con-vs-checkbox {
      align-items: flex-start;
      min-width: 60px;
    }

    .icon-header {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #2d99d3;
      color: #fff;
      right: -60px;
      top: 15px;

      .vs-icon {
        font-size: 0;
        position: relative;
        width: 100%;
        height: 100%;

        &:before {
          content: "\e8b1";
          font-family: feather;
          font-size: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .vs-collapse-item--content {
    .con-content--item {
      padding-left: 0 !important;
    }
  }

  &.open-item {
    .vs-collapse-item--header {
      .icon-header {
        .vs-icon {
          &:before {
            content: "\e897";
          }
        }
      }
    }
  }
}

.sticky {
  margin: -30px -30px 0;
  padding: 30px 30px 0;
  top: 0;
  z-index: 10;
  background: #ffffff;
}

.vuesax-app-is-ltr .vs-input--icon {
  font-size: 2rem;
  top: auto;
  left: 10px;
}

.spontaneous-btn {
  background-color: #28388d !important;
  color: white !important;
}
</style>
